<template>
  <section class="cards-container">
    <div class="card" @click="() => $router.push(`/categories`)">
      <h1>Total Categories</h1>
      <h2>{{ dashboardData?.totalCategories }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/services?type=approved`)">
      <h1>Total Approved Services Requests</h1>
      <h2>{{ dashboardData?.totalApproveServices }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/services?type=pending`)">
      <h1>Total Pending Services Requests</h1>
      <h2>{{ dashboardData?.totalPendingServices }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/cancelation-requests?type=approved`)">
      <h1>Total Cancelation Requests</h1>
      <h2>{{ dashboardData?.totalCancelServices }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/users?role=user`)">
      <h1>Total App Users</h1>
      <h2>{{ dashboardData?.totalAppUsers }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/users?role=admin`)">
      <h1>Total Admin Users</h1>
      <h2>{{ dashboardData?.totalAdminUsers }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/vendor`)">
      <h1>Total Vendors</h1>
      <h2>{{ dashboardData?.totalVendors }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/cities`)">
      <h1>Total Cities</h1>
      <h2>{{ dashboardData?.totalCities }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/bookings`)">
      <h1>Total Bookings</h1>
      <h2>{{ dashboardData?.totalBookings }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/services?type=approved`)">
      <h1>Total Services</h1>
      <h2>{{ dashboardData?.totalServices }}</h2>
      <h4>Updated today</h4>
    </div>
    <div class="card" @click="() => $router.push(`/deals`)">
      <h1>Total Deals</h1>
      <h2>{{ dashboardData?.totalDeals }}</h2>
      <h4>Updated today</h4>
    </div>
  </section>
</template>

<script>
export default {
  name: "dashboardCards",
  data() {
    return {
      dashboardData: "",
    };
  },
  methods: {
    async getData() {
      try {
        const response = await this.$axios.get(`admin/dashboard-data`);
        console.log(response.data);
        this.dashboardData = response?.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted(){
    this.getData();
  }
};
</script>

<style scoped>
.cards-container {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
  padding: 20px 0 6px 0;
}
.cards-container .card {
  text-align: center;
  margin: 0 0 -12px 0px;
  width: 15%;
  background: #fff;
  padding: 14px;
  border-radius: 5px;
  cursor: pointer;
}
.cards-container .card h1 {
  font-size: 14px;
  margin-bottom: 10px;
}
.cards-container .card h4 {
  font-size: 12px;
  color: #bfbfbf;
}
.cards-container .card h2 {
  font-size: 19px;
  color: #febb12;
  margin: 10px 0;
}
/* .cards-container .card:nth-child(4n+0) {
  margin-right: 0;
} */
</style>
