<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Settings</h2>
        <p>You can change the site settings here.</p>
      </div>
      <section class="primary">
        <div class="add-block">
          <div>
            <p>Please enter the following:</p>
          </div>
          <div style="display: flex">
            <div style="width: 50%">
              <div class="inputs">
                <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>Email:</b>
                  <input
                    type="email"
                    placeholder="Email"
                    v-model="settings.email"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>Mobile Number:</b>
                  <input
                    type="text"
                    placeholder="Mobile Number"
                    v-model="settings.phoneNo"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>Whatsapp Number:</b>
                  <input
                    type="text"
                    placeholder="Whatsapp Number"
                    v-model="settings.whatsAppNo"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>Address English:</b>
                  <input
                    type="text"
                    placeholder="Address English"
                    v-model="settings.address"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>Address Arabic:</b>
                  <input
                    type="text"
                    placeholder="Address Arabic"
                    v-model="settings.address_ar"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>Instagram:</b>
                  <input
                    type="text"
                    placeholder="Instagram"
                    v-model="settings.instagramLink"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>Twitter:</b>
                  <input
                    type="text"
                    placeholder="Twitter"
                    v-model="settings.twitterLink"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>Facebook:</b>
                  <input
                    type="text"
                    placeholder="Facebook"
                    v-model="settings.facebookLink"
                  />
                </div>
                <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>Youtube:</b>
                  <input
                    type="text"
                    placeholder="Youtube"
                    v-model="settings.youTubeLink"
                  />
                </div>
              </div>
            </div>
            <div style="margin-left: 40px">
              <div class="inputs">
                <b>Website Logo</b>
                <br />
                <div
                  class="upload-file"
                  style="display: flex; gap: 10px; align-items: center"
                >
                  <img style="width: 100px" :src="settings?.websiteLogo" />
                  <label for="websiteLogo">
                    Add&nbsp;image
                    <input
                      id="websiteLogo"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      @change="handlewebsiteLogo"
                    />
                  </label>
                </div>
                <br />
                <!-- <b>Mobile App Logo</b>
                <br />
                <div
                  class="upload-file"
                  style="display: flex; gap: 10px; align-items: center"
                >
                  <img style="width: 100px" :src="settings?.mobileAppLogo" />
                  <label for="mobileAppLogo">
                    Add&nbsp;image
                    <input
                      id="mobileAppLogo"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      @change="handlemobileAppLogo"
                    />
                  </label>
                  <br />
                </div> -->
                <br />
                <br />
                <!-- <div
                  style="
                    display: flex;
                    gap: 5px;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <b>System&nbsp;Name(SmartBooking):</b>
                  <input
                    type="text"
                    placeholder="System Name"
                    v-model="settings.systemName"
                  />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="uploadBtn">
        <button @click="update">
          {{ isLoading ? "Loading.." : "Update" }}
        </button>
      </div>
      <br />
      <br />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
export default {
  name: "SiteSettings",
  components: { DefaultLayout },
  data() {
    return {
      isLoading: false,
      settings: {},
      websiteLogo: "",
      mobileAppLogo: "",
    };
  },
  methods: {
    getData() {
      let url = "admin/get-setting";
      this.$axios.get(url).then((res) => {
        this.settings = res.data;
      });
    },
    async update() {
      this.isLoading = true;
      let url = "admin/setting";
      this.settings.websiteLogo = await this.uploadWebsiteLogo();
      this.settings.mobileAppLogo = await this.uploadMobileAppLogo();
      this.$axios.post(url, this.settings).then(() => {
        this.isLoading = false;
        this.$swal({
          title: "Updated!",
          text: "Settings updated successfully.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#FEBB12",
          confirmButtonText: "Done",
          reverseButtons: true,
        });
      });
    },
    handlewebsiteLogo(event) {
      console.log("web");
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      this.websiteLogo = formData;
      this.settings.websiteLogo = URL.createObjectURL(event.target.files[0]);
      console.log(this.websiteLogo);
      console.log(this.settings.websiteLogo);
    },
    handlemobileAppLogo(event) {
      console.log("mobile");
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      this.mobileAppLogo = formData;
      this.settings.mobileAppLogo = URL.createObjectURL(event.target.files[0]);
      console.log(this.mobileAppLogo);
      console.log(this.settings.mobileAppLogo);
    },
    async uploadWebsiteLogo() {
      try {
        const imagesData = await this.$axios.post(
          "user/upload",
          this.websiteLogo,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return imagesData.data.url;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async uploadMobileAppLogo() {
      try {
        const imagesData = await this.$axios.post(
          "user/upload",
          this.mobileAppLogo,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return imagesData.data.url;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    faqs: {
      immediate: true,
      handler() {
        console.log(this.settings);
      },
    },
  },
};
</script>

<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.primary {
  height: 630px;
  overflow-y: scroll;
}
.add-block {
  text-align: left;
  margin: 14px 0;
}
.add-block h4 {
  font-size: 14px;
  margin-bottom: 12px;
}
.add-block p {
  font-size: 12px;
}
.inputs input,
.inputs textarea {
  outline: none;
  font-size: 12px;
  padding: 14px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 50%;
  margin: 14px 0 8px 0;
}
.inputs textarea {
  height: 123px;
  border-radius: 20px;
}
.bottom-add p {
  width: 30%;
  color: #febb12;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}
.uploadBtn button {
  border: none;
  outline: none;
  border-radius: 24px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
  margin-top: 8px;
  margin-bottom: 10px;
}

.upload-file input {
  display: none;
}
.upload-file label {
  cursor: pointer;
  padding: 10px 24px;
  background: #febb12;
  color: #fff;
  font-size: 12px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}
</style>
