<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Wallet Management</h2>
        <p>You can see all the wallet transactions here.</p>
      </div>
      <div class="search-area">
        <div class="search-main">
          <div class="search-container">
            <input v-model="search" type="text" placeholder="Search here..." />
            <img src="../assets/images/search.svg" />
          </div>
          <div
            v-if="
              isSuperAdmin || wallet_management_permissions?.permissions?.create
            "
            class="add-btn"
          >
            <button @click="addBalanceModelShow">Add Balance</button>
          </div>
        </div>
      </div>
      <!-- <div class="add-btn">
          <button @click="DealModelShow">Add New Deal</button>
        </div> -->
      <div class="wallet-container">
        <div class="wallet-detail">
          <table>
            <tr>
              <th>ID</th>
              <th>Customer Name</th>
              <th>Type</th>
              <th>Amount (SAR)</th>
              <th>Date/Time</th>
              <th>Balance</th>
              <th>Action</th>
            </tr>
            <tr v-for="(wallet, index) in walletList" :key="index">
              <td>{{ wallet?.count }}</td>
              <td>{{ wallet?.name }}</td>
              <td>{{ wallet?.type }}</td>
              <td>{{ wallet?.amount }}</td>
              <td>
                {{
                  moment(wallet?.createdAt).format("ddd, D MMMM YYYY, h:mm A")
                }}
              </td>
              <td>{{ wallet?.balance }}</td>

              <td>
                <div class="view-btn">
                  <button @click="WalletModelShow(wallet)">View</button>
                </div>
              </td>
            </tr>
          </table>
          <div class="bottom-container">
            <div>
              <p>
                Showing
                {{ pageSelected != 1 ? dataShow * pageSelected - dataShow : 1 }}
                to
                {{
                  walletList[walletList.length - 1] &&
                  walletList[walletList.length - 1].count
                }}
                of {{ walletData.length }} entries
              </p>
            </div>
            <div class="wallet-pagination">
              <paginate
                v-model="pageSelected"
                :page-range="3"
                :margin-pages="2"
                :page-count="pageCount"
                :click-handler="clickCallback"
                :prev-text="'Previous'"
                :next-text="''"
                :container-class="'pagination'"
                :page-class="'page-item'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
      <WalletDetailModel
        v-if="walletModel"
        :selectedWallet="selectedWallet"
        @call="close"
      />
      <AddBalanceModel v-if="addBalanceModel" @call="close" />
    </section>
  </default-layout>
</template>

<script>
import moment from "moment";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import AddBalanceModel from "@/components/Models/AddBalanceModel.vue";
import WalletDetailModel from "@/components/Models/WalletDetailModel.vue";
import Paginate from "vuejs-paginate-next";

export default {
  name: "WalletManagement",
  components: {
    DefaultLayout,
    Paginate,
    AddBalanceModel,
    WalletDetailModel,
  },
  data() {
    return {
      addBalanceModel: false,
      wallet_management_permissions: "",
      walletModel: false,
      walletData: [],
      walletList: [],
      allData: [],
      //
      pageCount: 0,
      selectedWallet: {},
      total: 0,
      pageSelected: 1,
      //
      dataShow: 10,
      search: "",
    };
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    clickCallback(num) {
      var copyFrom = num * this.dataShow - this.dataShow;
      var copyTo = num * this.dataShow;
      this.walletList = this.walletData.slice(copyFrom, copyTo);
    },
    addBalanceModelShow() {
      this.addBalanceModel = !this.addBalanceModel;
    },
    WalletModelShow(walletObj) {
      this.selectedWallet = walletObj;
      this.walletModel = !this.walletModel;
    },
    reSet() {
      this.pageSelected = 1;
      this.walletData = [];
      this.walletList = [];
    },
    async getRequests() {
      this.reSet();
      try {
        const transactions = await this.$axios.get("admin/get-transactions");

        this.total = transactions.data.length;
        //asigning number
        this.walletData = [];
        for (let index = 0; index < transactions.data.length; index++) {
          const element = transactions.data[index];
          element.count = index + 1;
          this.walletData.push(element);
        }
        this.pageCount = Math.ceil(this.total / this.dataShow);
        this.allData = [...this.walletData];
        this.walletList = this.walletData.slice(0, this.dataShow);

        console.log(this.walletList);
      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.walletModel = false;
      this.addBalanceModel = false;
      this.getRequests();
    },
    setPermissions() {
      let permissions = this.$store.state?.user?.resources;

      this.isSuperAdmin =
        this.$store.state?.user?.role === "SuperAdmin" ? true : false;

      this.wallet_management_permissions = permissions?.find(
        (permission) => permission.name == "wallet_management"
      );
    },
  },
  watch: {
    "$store.state.user": {
      handler() {
        this.setPermissions();
      },
      immediate: true,
    },
    search: function (newVal) {
      const allData = JSON.parse(JSON.stringify(this.allData));
      let filterResults = allData.filter((i) => {
        let result = false;
        if (i.name) {
          result =
            result ||
            i.name.toLowerCase().match(new RegExp(newVal.toLowerCase()));
        }
        if (i.phone) {
          result = result || String(i.phone).match(new RegExp(newVal));
        }
        return result;
      });

      if (newVal === "") {
        filterResults = allData;
      }
      this.walletdata = filterResults;

      this.total = filterResults.length;
      for (let index = 0; index < filterResults.length; index++) {
        const element = filterResults[index];
        element.count = index + 1;
      }
      this.pageCount = Math.ceil(this.total / this.dataShow);
      this.walletList = filterResults.slice(0, this.dataShow);

      console.log(newVal);
    },
  },
};
</script>
<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 100%;
}
.wallet-detail {
  padding: 20px 0 40px 0;
}
.wallet-detail table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0px 0px 39px #00000012;
  border-radius: 11px;
  opacity: 1;
  background: #fff;
}

.wallet-detail table th {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding: 15px 5px;
  white-space: nowrap;
}
.wallet-detail table td {
  padding: 15px 5px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-weight: normal;
  color: #9a9a9a;
  white-space: nowrap;
  font-size: 12px;
}
.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.view-btn button {
  border: none;
  outline: none;
  border-radius: 7px;
  opacity: 1;
  background: #febb12;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 50px;
  cursor: pointer;
}
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-container div p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 12px;
}

.search-area {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.search-container {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 0 20px;
  margin-right: 10px;
  height: 46px;
}

.search-main {
  display: flex;
}
.search-container img {
  width: 15px;
}
.search-container input {
  outline: none;
  border: none;
  /* height: 38px; */
  color: #828282;
  width: 230px;
}
</style>
