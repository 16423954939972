<template>
  <section class="login-signup">
    <div class="primary-login">
      <div class="close-icon" @click="closeSlide">
        <img src="../../assets/images/close-icon.svg" alt />
      </div>
      <div class="service-heading">
        <h2>Edit Vendor</h2>
      </div>
      <br />
      <section class="service-popup">
        <Form @submit="addNewCategory" :validation-schema="schema">
          <div class="category-content">
            <div class="inputs">
              <div class="input-wrapper">
                <Field
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  v-model="firstName"
                  required
                />
                <ErrorMessage class="error-msg" name="firstName" />
              </div>
              <div class="input-wrapper">
                <Field
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  v-model="lastName"
                  required
                />
                <ErrorMessage class="error-msg" name="lastName" />
              </div>
              <div class="input-wrapper">
                <Field
                  name="email"
                  type="text"
                  placeholder="Email"
                  v-model="email"
                  required
                />
                <ErrorMessage class="error-msg" name="email" />
              </div>
              <div class="input-wrapper">
                <Field
                  name="phone"
                  type="text"
                  placeholder="Phone Number"
                  v-model="phone"
                  required
                />
                <ErrorMessage class="error-msg" name="phone" />
              </div>
              <div class="input-wrapper">
                <Field
                  name="address"
                  type="text"
                  placeholder="Address"
                  v-model="address"
                  required
                />
                <ErrorMessage class="error-msg" name="address" />
              </div>
              <div class="input-wrapper">
                <Field
                  name="idNumber"
                  type="text"
                  placeholder="ID number"
                  v-model="idNumber"
                  required
                />
                <ErrorMessage class="error-msg" name="idNumber" />
              </div>
              <div class="input-wrapper">
                <Field
                  name="nationality"
                  type="text"
                  placeholder="Nationality"
                  v-model="nationality"
                  required
                />
                <ErrorMessage class="error-msg" name="nationality" />
              </div>
              <div class="input-wrapper">
                <Field
                  v-if="company"
                  name="commId"
                  type="text"
                  placeholder="Commercial ID"
                  v-model="commId"
                  required
                />
                <ErrorMessage class="error-msg" name="commId" />
              </div>
            </div>
            <div class="vendor-type">
              Vendor Type:
              <div class="flex">
                <input type="checkbox" :checked="host" disabled />
                Host
              </div>
              <div class="flex">
                <input type="checkbox" :checked="company" disabled />
                Company
              </div>
            </div>
            <div class="upload-file">
              <p>Add Vendor Image</p>
              <div v-if="url != ''" class="icon">
                <img class="hotel-icon" :src="url" />
              </div>
              <label for="inputTag" :class="{ active: err.file }">
                Add image
                <input
                  id="inputTag"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  @change="handleFile"
                />
              </label>
            </div>
            <div class="upload-file">
              <p>Add ID Photo</p>
              <div v-if="idPhotoUrl != ''" class="icon">
                <img class="hotel-icon" :src="idPhotoUrl" />
              </div>
              <label for="IDinput" :class="{ active: err.idFile }">
                Add image
                <input
                  id="IDinput"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  @change="handleIdFile"
                />
              </label>
            </div>
            <div v-if="company" class="upload-file">
              <p>Add Commercial ID Photo</p>
              <div v-if="commercialIdPhotoUrl != ''" class="icon">
                <img class="hotel-icon" :src="commercialIdPhotoUrl" />
              </div>
              <label
                for="commercialIDinput"
                :class="{ active: err.commercialIdFile }"
              >
                Add image
                <input
                  id="commercialIDinput"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  @change="handleCommercialIdFile"
                />
              </label>
            </div>
            <div class="add-btn">
              <button type="submit" :disabled="loading">
                {{ !loading ? "Save" : "Loading..." }}
              </button>
            </div>
          </div>
        </Form>
      </section>
    </div>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "editVendorModel",
  props: ["editData"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      firstName: this.editData.firstName,
      lastName: this.editData.lastName,
      email: this.editData.email,
      phone: this.editData.phone,
      address: this.editData.address,
      idNumber: this.editData.idNumber,
      commId: this.editData.commId,
      nationality: this.editData.nationality,
      host: this.editData.host,
      company: this.editData.company,
      file: null,
      idFile: null,
      commercialIdFile: null,
      url: this.editData.file,
      idPhotoUrl: this.editData.idPhoto,
      commercialIdPhotoUrl: this.editData.commercialIdPhoto,
      vendorId: this.editData._id,
      //
      err: {},
      loading: false,
    };
  },
  computed: {
    schema() {
      const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
      return yup.object({
        firstName: yup.string().trim().required().min(3).label("First Name"),
        lastName: yup.string().trim().required().min(3).label("Last Name"),
        email: yup.string().trim().required().email().label("Email"),
        phone: yup
          .string()
          .required()
          .matches(phoneRegExp, "Phone number is not valid"),
        address: yup.string().trim().required().min(3).label("Address"),
        idNumber: yup.string().trim().required().min(3).label("Id Number"),
        commId: yup.string().trim().optional().min(3).label("Community Id"),
        nationality: yup.string().trim().required().min(3).label("Nationality"),
      });
    },
  },
  methods: {
    handleFile(event) {
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      this.file = formData;
      this.url = URL.createObjectURL(event.target.files[0]);
      console.log(this.url);
      console.log(this.file);
    },
    handleIdFile(event) {
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      this.idFile = formData;
      this.idPhotoUrl = URL.createObjectURL(event.target.files[0]);
      console.log(this.idFile);
      console.log(this.idPhotoUrl);
    },
    handleCommercialIdFile(event) {
      let formData = new FormData();
      formData.append("image", event.target.files[0]);
      this.commercialIdFile = formData;
      this.commercialIdPhotoUrl = URL.createObjectURL(event.target.files[0]);
      console.log(this.commercialIdFile);
      console.log(this.commercialIdPhotoUrl);
    },
    async addNewCategory() {
      this.loading = true;
      this.err.file = false;
      this.err.idFile = false;
      this.err.commercialIdFile = false;
      var imageUrl = await this.uploadFiles();
      var idPhotoUrl = await this.uploadIdFiles();
      var commercialIdPhotoUrl = await this.uploadCommercialIdFiles();
      this.add(imageUrl, idPhotoUrl, commercialIdPhotoUrl);
    },
    async add(imageUrl, idPhotoUrl, commercialIdPhotoUrl) {
      try {
        var res = this.$axios.put(`/admin/update/${this.vendorId}`, {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          address: this.address,
          idNumber: this.idNumber,
          commId: this.commId,
          nationality: this.nationality,
          host: this.host,
          company: this.company,
          role: "Vender",
          file: imageUrl,
          idPhoto: idPhotoUrl,
          commercialIdPhoto: commercialIdPhotoUrl,
        });
        if (res) {
          this.loading = false;
          this.$swal({
            icon: "success",
            title: "Vendor Updated Successfully",
            showConfirmButton: false,
            timer: 3000,
          });
          this.closeSlide();
          this.$emit("call");
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async uploadFiles() {
      try {
        const imagesData = await this.$axios.post("user/upload", this.file, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return imagesData.data.url;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async uploadIdFiles() {
      try {
        const imagesData = await this.$axios.post("user/upload", this.idFile, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return imagesData.data.url;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async uploadCommercialIdFiles() {
      try {
        const imagesData = await this.$axios.post(
          "user/upload",
          this.commercialIdFile,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return imagesData.data.url;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    closeSlide() {
      this.$parent.$parent.editVendorModel = false;
    },
    tickHost() {
      this.host = !this.host;
      this.company = !this.company;
    },
    tickCompany() {
      this.host = !this.host;
      this.company = !this.company;
    },
  },
};
</script>

<style scoped>
.service-popup {
  height: 90%;
  overflow-y: scroll;
}
.login-signup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.primary-login {
  width: 700px;
  height: 80vh;
  background: #fff;
  padding: 25px;
  border-radius: 20px;
  position: relative;
}
.service-popup::-webkit-scrollbar {
  display: none;
}
.primary-login {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.close-icon {
  cursor: pointer;
  top: 26px;
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 99;
}
.service-heading h2 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  font-size: 18px;
}
.category-content {
  padding: 0 50px;
}
.category-content .inputs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.input-wrapper {
  display: flex;
  width: 26%;
  flex-direction: column;
  padding: 5px 12px;
  margin: 15px 8px 8px 8px;
}
.category-content .inputs input {
  outline: none;
  font-size: 12px;
  padding: 15px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 90%;
}

.error-msg {
  padding-top: 6px;
  margin-bottom: 0px;
  font-size: 12px;
  color: red;
  text-align: left;
}
.upload-file input {
  display: none;
}
.upload-file {
  margin: 30px 0px 10px 0px;
  padding: 15px;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  border-radius: 20px;
  line-height: 1.8;
}
.upload-file p {
  letter-spacing: 0px;
  color: #393f45;
  font-size: 12px;
  padding-bottom: 20px;
  line-height: 1.8;
}
.upload-file label {
  cursor: pointer;
  padding: 10px 24px;
  background: #febb12;
  color: #fff;
  font-size: 12px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}
.upload-file label.active {
  border: 1px solid red !important;
}
.add-btn {
  margin: 40px 0 10px 0;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}

.icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #f0f2f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.vendor-type {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.flex {
  display: flex;
}

/* responsive */
@media (max-width: 479px) and (min-width: 320px) {
  .primary-login {
    width: 84%;
  }
}
</style>
