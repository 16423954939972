<template>
  <section>
    <LoginModel />
  </section>
</template>

<script>
import LoginModel from "@/components/Models/LoginModel.vue";
export default {
  name: "loginView",
  components: {
    LoginModel,
  },
};
</script>

<style scoped>
</style>