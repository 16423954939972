<template>
  <section>
    <div class="container primary-container">
      <SideBar />
      <section
        :class="[
          'pages-slide',
          { 'set-height': $route.path.includes('/permissions/') },
        ]"
      >
        <slot />
      </section>
    </div>
  </section>
</template>

<script>
import SideBar from "../common/SideBar.vue";
export default {
  name: "DefaultLayout",
  components: { SideBar },
  data() {
    return {
      adminId: "",
    };
  },
  mounted() {
    const adminId = localStorage.getItem("adminInfo");
    this.adminId = adminId;
    this.getAdminInfo();
  },
  methods: {
    async getAdminInfo() {
      try {
        const admin = await this.$axios.get(`admin/users/${this.adminId}`);
        if (admin) {
          this.$store.commit("ADMIN_INFO", admin);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.primary-container {
  display: flex;
}
.pages-slide {
  background: #f4f7fa;
  width: 76%;
  padding: 20px 20px 45px 20px;
  min-height: 100vh;
  height: auto;
}
.set-height {
  height: auto;
}
</style>
