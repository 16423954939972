<template>
  <section class="login-signup">
    <div class="primary-login">
      <div class="close-icon" @click="closeSlide">
        <img src="../../assets/images/close-icon.svg" alt />
      </div>
      <section class="service-popup">
        <HeaderCard :service="service" />
        <DetailTabs :service="service" />
      </section>
      <div class="id-image">
        <div class="add-btn">
          <button
            @click="Approve"
            v-if="
              $route.query.type === 'pending' ||
              $route.query.type === 'suspended'
            "
          >
            Approve
          </button>
          <button @click="Suspend" v-else-if="$route.query.type === 'approved'">
            Suspend
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import HeaderCard from "@/components/Models/Service/HeaderCard.vue";
import DetailTabs from "@/components/Models/Service/DetailTabs.vue";
export default {
  name: "ServicesModel",
  props: ["service"],
  components: {
    HeaderCard,
    DetailTabs,
  },
  data() {
    return {};
  },
  methods: {
    Suspend() {
      const imagePath = require("../../assets/images/cancelicon.png");
      this.$swal({
        title: "You want to suspend/unpublish?",
        text: "You want to suspend/unpublish the service?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.suspendConfirmed();
        }
      });
    },
    async suspendConfirmed() {
      try {
        var res = await this.$axios.put(
          `admin/service/${this.service._id}/un-publish`
        );
        if (res) {
          this.$emit("call");
          this.$swal({
            title: "Suspended",
            text: "Your service has been Suspended successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#FEBB12",
            confirmButtonText: "Done",
            reverseButtons: true,
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Some Thing Went Wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
    Approve() {
      const imagePath = require("../../assets/images/cancelicon.png");
      this.$swal({
        title: "You want to Approve?",
        text: "You want to Approve the service?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.publishedConfirmed();
          console.log("result");
        }
      });
    },
    async publishedConfirmed() {
      try {
        var res = await this.$axios.put(
          `admin/service/${this.service._id}/publish`
        );
        if (res) {
          this.$emit("call");
          this.$swal({
            title: "Approved",
            text: "Your service has been Approved successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#FEBB12",
            confirmButtonText: "Done",
            reverseButtons: true,
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Some Thing Went Wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
    closeSlide() {
      this.$parent.$parent.servicesModel = false;
    },
  },
};
</script>

<style scoped>
.login-signup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.primary-login {
  text-align: left;
  width: 90%;
  background: #fff;
  padding: 25px 25px 0 25px;
  border-radius: 20px;
  position: relative;
}
.service-popup {
  height: 80vh;
  overflow-y: scroll;
}
.service-popup::-webkit-scrollbar {
  display: none;
}
.primary-login {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.close-icon {
  cursor: pointer;
  top: 26px;
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 99;
}

.id-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.id-image div h3 {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
  opacity: 0.7;
}
.id-image div .placeholder-img {
  width: 250px;
  height: 130px;
  background: #eee;
  margin: 15px 0;
  border-radius: 11px;
}
.add-btn {
  width: 30%;
  margin: 20px;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}
/* responsive */
@media (max-width: 479px) and (min-width: 320px) {
  .primary-login {
    width: 84%;
  }
}
</style>
