<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Main Banner</h2>
        <p>You can manage the homepage Main Banner contents here.</p>
      </div>
      <!-- <div class="add-btn">
            <button @click="DealModelShow">Add New Deal</button>
          </div> -->
      <div v-for="banner in mainBanner" :key="banner?._id" class="main-banner-editor">
        <div class="main-banner-editor-content">
          <div>
            <h3>Website</h3>
            <br />
            <div class="banner-image">
              <img :src="banner?.websiteImage" />
            </div>
          </div>
          <div>
            <h3>Mobile App</h3>
            <br />
            <div class="banner-image">
              <img :src="banner?.mobileImage" />
            </div>
          </div>
        </div>
        <br />
        <div>Link: {{ banner?.link }}</div>
        <button class="delete-btn" @click="Delete(banner?._id)">Delete</button>
        <button class="edit-btn" @click="editBannerModelShow(banner)">Edit</button>
      </div>
      
      <br />
      <div class="add-btn">
        <button @click="addBannerModelShow('main')">Add New</button>
      </div>
      <br /><br />
      <div class="top-heading">
        <h2>Banner</h2>
        <p>You can manage the Banner contents here.</p>
      </div>
      <div v-for="banner in smallBanner" :key="banner?._id" class="main-banner-editor">
        <div class="main-banner-editor-content">
          <div>
            <h3>Website</h3>
            <br />
            <div class="banner-image">
              <img :src="banner?.websiteImage" />
            </div>
          </div>
          <div>
            <h3>Mobile App</h3>
            <br />
            <div class="banner-image">
              <img :src="banner?.mobileImage" />
            </div>
          </div>
        </div>
        <br />
        <div>Link: {{ banner?.link }}</div>
        <button class="delete-btn" @click="Delete(banner?._id)">Delete</button>
        <button class="edit-btn" @click="editBannerModelShow(banner)">Edit</button>
      </div>
      <br />
      <div class="add-btn">
        <button @click="addBannerModelShow('small')">Add New</button>
      </div>
      <br />
      <br />
      <br />
      <br />

      <AddBannerModel v-if="addBannerModel" :bannerType="this.banner_type" @call="close" />
      <EditBannerModel v-if="editBannerModel" :banner="this.selectedBanner" @call="close" />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import AddBannerModel from "@/components/Models/AddBannerModel.vue";
import EditBannerModel from "@/components/Models/EditBannerModel.vue";

export default {
  name: "HomepageManagement",
  components: {
    DefaultLayout,
    AddBannerModel,
    EditBannerModel
  },
  data() {
    const banner_placeholder_1 = require("../assets/images/home-content-1.jpg");
    const banner_placeholder_2 = require("../assets/images/home-content-2.jpg");
    const banner_placeholder_3 = require("../assets/images/home-content-3.jpg");
    const banner_placeholder_4 = require("../assets/images/home-content-4.jpg");
    return {
      banner_placeholder_1,
      banner_placeholder_2,
      banner_placeholder_3,
      banner_placeholder_4,
      addBannerModel: false,
      editBannerModel: false,
      selectedBanner: {},
      mainBanner: [],
      smallBanner: [],
      banner_del_id: '',
      banner_type: ''
    };
  },
  mounted(){
    this.getRequests();
  },
  methods: {
    clickCallback(num) {
      var copyFrom = num * this.dataShow - this.dataShow;
      var copyTo = num * this.dataShow;
      this.homepageList = this.homepageData.slice(copyFrom, copyTo);
    },
    addBannerModelShow(type) {
      this.banner_type = type;
      this.addBannerModel = !this.addBannerModel;
    },
    editBannerModelShow(banner) {
      this.selectedBanner = banner;
      this.editBannerModel = !this.editBannerModel;
    },
    async getRequests() {
      this.$axios.get("admin/main-banner").then((res)=>{
        console.log(res.data);
        this.mainBanner = res.data;
      });
      this.$axios.get("admin/banner").then((res)=>{
        console.log(res.data);
        this.smallBanner = res.data;
      });
    },
    close() {
      this.selectedHomepage = false;
      this.addBannerModel = false;
      this.getRequests();
    },
    Delete(id) {
      const imagePath = require("../assets/images/cancelicon.png");
      this.banner_del_id = id;
      this.$swal({
        title: "You want to Delete?",
        text: "You want to Delete the Banner?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.publishedConfirmed();
          console.log("result");
        }
      });
    },
    async publishedConfirmed() {
      try {
        var res = await this.$axios.delete(
          `admin/banner/${this.banner_del_id}`
        );
        if (res) {
          this.$emit("call");
          this.getRequests();
          this.$swal({
            title: "Deleted",
            text: "The Banner is Deleted successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#FEBB12",
            confirmButtonText: "Done",
            reverseButtons: true,
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Some Thing Went Wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 160px;
}

.main-banner-editor {
  position: relative;
  text-align: left;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.main-banner-editor-content {
  display: flex;
  justify-content: left;
}

.main-banner-editor-content div {
  width: 50%;
}

.banner-image {
  width: 90% !important;
  height: 250px;
}

.banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.delete-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  background: #febb12;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-btn {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 60px;
  background: #febb12;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
