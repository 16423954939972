<template>
  <section class="logo">
   <router-link to="/">
    <img src="../../assets/images/logo.svg" alt="" />
    </router-link>
  </section>
</template>

<script>
export default {
  name: "LogoComp",
};
</script>

<style scoped>
.logo {
  width: 70px;
  padding: 14px 0;
}
</style>