<template>
  <section>
    <div class="detail-tabs">
      <div class="tabs">
        <p
          :class="{ active: tab == 'Facilities' }"
          @click="selected('Facilities')"
        >
          Facilities
        </p>
        <p :class="{ active: tab == 'Settings' }" @click="selected('Settings')">
          Settings
        </p>
        <p :class="{ active: tab == 'Vendor' }" @click="selected('Vendor')">
          Vendor
        </p>
        <p :class="{ active: tab == 'Reviews' }" @click="selected('Reviews')">
          Reviews
        </p>
        <p :class="{ active: tab == 'Terms' }" @click="selected('Terms')">
          Terms
        </p>
        <p :class="{ active: tab == 'Map' }" @click="selected('Map')">Map</p>
      </div>
    </div>
    <FacilityDetail v-if="tab == 'Facilities'" :service="service" />
    <section v-if="tab == 'Settings'">
      <div v-if="service.price">
        <h3>Price & Timing</h3>
        <br />
        <p>
          {{ service?.category.type == "Stadium" ? "Hourly" : "Daily" }} Price:
          {{
            service?.category.type == "Stadium"
              ? service.price.hourPrice
              : showPrice()
          }}
          SAR <br /><br />
          Start Date & Time:
          {{
            moment(service.price.openFrom).format("ddd, D MMMM YYYY, h:mm A")
          }}
          &nbsp; - &nbsp; End Date & Time:
          {{ moment(service.price.openTo).format("ddd, D MMMM YYYY, h:mm A") }}
        </p>
      </div>
      <br /><br />
      <div v-if="service.roomsGuest">
        <h3>Guest Capacity</h3>
        <br />
        <p>
          Maximum Guest: {{ service.roomsGuest?.maxGuest }} <br /><br />
          <!-- <span v-if="service?.category.type != 'Stadium'"
            >Room Type: {{ service.roomsGuest?.roomType }} <br /><br
          /></span> -->
          Bath Rooms: {{ service.roomsGuest?.bathrooms }} <br /><br />
          <span v-if="service?.category.type != 'Stadium'"
            >Single Beds: {{ service.roomsGuest?.singleBed }}</span
          >
        </p>
      </div>
      <br /><br />
      <div v-if="service?.check?.checkInTime">
        <h3>Checkings</h3>
        <br />
        <p>
          Check In: {{ service?.check?.checkInTime }} &nbsp; - &nbsp; Check Out:
          {{ service?.check?.checkOutTime }}
        </p>
      </div>
      <br />
    </section>
    <section v-if="tab == 'Vendor'">
      <div>
        <h3>Vendor Details</h3>
        <br />
        <p>
          Name: {{ service.vender.firstName }} {{ service.vender.lastName }}
          <br /><br />
          Phone: {{ service.vender.phone }} <br /><br />
          Email: {{ service.vender.email }} <br /><br />
          Address: {{ service.vender.address }} <br /><br />
          Balance: {{ service.vender.balance }} SAR<br /><br />
        </p>
      </div>
      <br /><br />
    </section>
    <ReviewsDetail v-if="tab == 'Reviews'" :reviews="service.reviews" />
    <section v-if="tab == 'Terms'" class="terms">
      <div>
        <div class="title">
          <h1>Cancellation Policy</h1>
        </div>
        <div
          :class="'primary-cards'"
          v-if="service.bookingSetting?.canellationPolicy == 'Flexible'"
        >
          <!-- <div class="rounded-btn"></div> -->
          <div class="content">
            <h5>Flexible</h5>
            <h6>
              Cancellation Policy allows guests to cancel up to 24 hours prior
              to check-in date and receive a full refund.
            </h6>
          </div>
        </div>
        <div
          :class="'primary-cards'"
          v-if="service.bookingSetting?.canellationPolicy == 'Moderate'"
        >
          <!-- <div class="rounded-btn"></div> -->
          <div class="content">
            <h5>Moderate</h5>
            <h6>
              Cancellation Policy allows guests to cancel up to 3 days prior to
              check-in date and receive a full refund.
            </h6>
          </div>
        </div>
        <div
          :class="'primary-cards'"
          v-if="service.bookingSetting?.canellationPolicy == 'Strict'"
        >
          <!-- <div class="rounded-btn"></div> -->
          <div class="content">
            <h5>Strict</h5>
            <h6>
              Cancellation Policy allows guests to cancel up to 7 days prior to
              check-in date and receive a full refund.
            </h6>
          </div>
        </div>
        <div
          :class="'primary-cards'"
          v-if="service.bookingSetting?.canellationPolicy == 'Non-Refundable'"
        >
          <!-- <div class="rounded-btn"></div> -->
          <div class="content">
            <h5>Non refundable</h5>
            <h6>
              Cancellation policy allows guests to cancel the reservation but
              booking fees are non-refundable.
            </h6>
          </div>
        </div>
      </div>
      <!-- <p v-else>{{ $t("error.notFound") }}</p> -->
    </section>
    <section v-if="tab == 'Map'" class="terms">
      <MapDetail :service="service" />
    </section>
  </section>
</template>

<script>
import moment from "moment";
import FacilityDetail from "./FacilityDetail.vue";
import ReviewsDetail from "./ReviewsDetail.vue";
import MapDetail from "./MapDetail.vue";
export default {
  name: "DetailTabs",
  props: ["service"],
  components: {
    FacilityDetail,
    MapDetail,
    ReviewsDetail,
  },

  data() {
    return {
      tab: "Facilities",
    };
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    selected(val) {
      this.tab = val;
    },
    showPrice() {
      var days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      var d = new Date(Date.now());
      var dayName = days[d.getDay()];

      if (dayName == "sunday") {
        if (this.service.price.sundayPrice != 0) {
          return this.service.price.sundayPrice;
        } else {
          return this.service.price.dayPrice;
        }
      } else if (dayName == "monday") {
        if (this.service.price.mondayPrice != 0) {
          return this.service.price.mondayPrice;
        } else {
          return this.service.price.dayPrice;
        }
      } else if (dayName == "tuesday") {
        if (this.service.price.tuesdayPrice != 0) {
          return this.service.price.tuesdayPrice;
        } else {
          return this.service.price.dayPrice;
        }
      } else if (dayName == "wednesday") {
        if (this.service.price.wednesdayPrice != 0) {
          return this.service.price.wednesdayPrice;
        } else {
          return this.service.price.dayPrice;
        }
      } else if (dayName == "thursday") {
        if (this.service.price.thrusdayPrice != 0) {
          return this.service.price.thrusdayPrice;
        } else {
          return this.service.price.dayPrice;
        }
      } else if (dayName == "friday") {
        if (this.service.price.fridayPrice != 0) {
          return this.service.price.fridayPrice;
        } else {
          return this.service.price.dayPrice;
        }
      } else if (dayName == "saturday") {
        if (this.service.price.saturdayPrice != 0) {
          return this.service.price.saturdayPrice;
        } else {
          return this.service.price.dayPrice;
        }
      }
    },
  },
};
</script>

<style scoped>
.terms p {
  text-align: left;
  font-size: 14px;
  color: #c2c2c2;
  line-height: 1.5;
}
.detail-tabs {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.detail-tabs .tabs {
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 11px;
  opacity: 1;
  width: 65%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-tabs .tabs p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.4;
  font-size: 12px;
  padding: 15px 25px;
  cursor: pointer;
}
.detail-tabs .tabs .active {
  color: #000000;
  background-color: #febb12;
  border-radius: 12px;
  opacity: 1;
}
.detail-tabs .tabs p:hover,
.detail-tabs .tabs p:active {
  color: #000000;
  background-color: #febb12;
  border-radius: 12px;
  opacity: 1;
}
.set-lang p {
  text-align: right;
}
.primary-cards {
  width: 46%;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 1px 11px -5px #0000006b;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0px 15px;
  text-align: left;
  margin-bottom: 22px;
  position: relative;
}
.booking-type .primary-cards .content {
  text-align: left;
  line-height: 1.5;
}
.primary-cards h5 {
  font-size: 14px;
}
.primary-cards h6 {
  color: #393f45;
  margin-top: 3px;
  font-size: 12px;
}
.set-lang .primary-cards {
  text-align: right;
}
.terms .title h1 {
  text-align: left;
  margin: 10px 0;
  font-size: 18px;
}
.set-lang .terms .title h1 {
  text-align: right;
  margin: 10px 0;
}
@media (max-width: 700px) and (min-width: 320px) {
  .detail-tabs .tabs {
    width: 100%;
  }
  .detail-tabs .tabs p {
    padding: 12px 15px;
  }
  .main-container-div .primary-container {
    width: 89% !important;
  }
}
</style>
