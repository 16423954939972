<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Profile</h2>
        <p>Get your Profile Details here.</p>
      </div>
      <!-- <div class="add-btn">
          <button @click="DealModelShow">Add New Deal</button>
        </div> -->
      <div class="service-container">
        <div class="user-details">
          <img
            :src="
              $store.state?.user.image ? $store.state?.user.image : placeholder
            "
          />
          <br />
          <h1>
            {{ $store.state?.user.firstName }} {{ $store.state?.user.lastName }}
          </h1>
          <p>{{ $store.state?.user.email }}</p>
          <p>Your Balance: {{ $store.state?.user.balance }} SAR</p>
          <br /><br />
          <h6>Change Password</h6>
          <br />
          <Form @submit="changePass" :validation-schema="schema">
            <div class="inputs">
              <div class="input-wrapper">
                <div class="pass-field">
                  <Field
                    name="oldPassword"
                    :type="showOldPass ? 'text' : 'password'"
                    placeholder="Old Password"
                    v-model="oldPassword"
                    required
                  />
                  <img
                    class="eye-icon"
                    v-if="!showOldPass"
                    src="../assets/images/eye-pass-open.svg"
                    @click="showOldPass = !showOldPass"
                  />
                  <img
                    class="eye-icon"
                    v-if="showOldPass"
                    src="../assets/images/eye-pass-close.svg"
                    @click="showOldPass = !showOldPass"
                  />
                </div>
                <ErrorMessage class="error-msg" name="oldPassword" />
              </div>
              <div class="input-wrapper">
                <div class="pass-field">
                  <Field
                    name="newPassword"
                    :type="showNewPass ? 'text' : 'password'"
                    placeholder="New Password"
                    v-model="newPassword"
                    required
                  />
                  <img
                    class="eye-icon"
                    v-if="!showNewPass"
                    src="../assets/images/eye-pass-open.svg"
                    @click="showNewPass = !showNewPass"
                  />
                  <img
                    class="eye-icon"
                    v-if="showNewPass"
                    src="../assets/images/eye-pass-close.svg"
                    @click="showNewPass = !showNewPass"
                  />
                </div>
                <ErrorMessage class="error-msg" name="newPassword" />
              </div>
            </div>
            <br /><br />
            <div class="add-btn">
              <button>Update Password</button>
            </div>
          </Form>
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
import moment from "moment";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "ServicesDetailsView",
  components: {
    DefaultLayout,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const placeholder = require("../assets/images/placeholder.jpg");
    return {
      placeholder,
      showOldPass: false,
      showNewPass: false,
      oldPassword: "",
      newPassword: "",
    };
  },
  computed: {
    schema() {
      return yup.object({
        oldPassword: yup
          .string()
          .trim()
          .required()
          .min(3)
          .label("Old Password"),
        newPassword: yup
          .string()
          .trim()
          .required()
          .min(3)
          .label("New Password"),
      });
    },
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    async changePass() {
      this.$axios
        .post("/admin/reset-password", {
          id: this.$store.state?.user._id,
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then(() => {
          this.oldPassword = "";
          this.newPassword = "";
          this.$swal({
            toast: true,
            title: "Password Updated!",
            icon: "success",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
          });
        }).catch((err)=>{
          console.log(err);
            this.oldPassword = "";
          this.newPassword = "";
          this.$swal({
            toast: true,
            title: err?.response?.data?.error,
            icon: "error",
            animation: false,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
  },

  mounted() {
    console.log(this.$store.state?.user);
  },
};
</script>
<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
}
.user-details {
  padding: 20px 0 40px 0;
}

.user-details img {
  width: 100px;
  border-radius: 50%;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
}

.input-wrapper {
  display: flex;
  width: 26%;
  flex-direction: column;
  padding: 5px 12px;
  margin: 15px 8px 8px 8px;
}

input {
  outline: none;
  font-size: 12px;
  padding: 15px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 90%;
}

.pass-field {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 0px;
  right: 20px;
  max-width: 20px;
}

.eye-icon img {
  width: 100%;
  cursor: pointer;
  object-fit: contain !important;
}

.error-msg {
  padding-top: 6px;
  margin-bottom: 0px;
  font-size: 12px;
  color: red;
  text-align: left;
}
</style>
