<template>
    <default-layout>
      <section class="main-wrapper">
        <div class="top-heading">
          <h2>Customer Wallet Report</h2>
          <p>You can see all the reports here.</p>
        </div>
        <div class="search-area">
          <div class="search-main">
            <div class="search-container">
              <input v-model="search" type="text" placeholder="Search here..." />
              <img src="../assets/images/search.svg" />
            </div>
          </div>
        </div>
        <!-- <div class="add-btn">
            <button @click="DealModelShow">Add New Deal</button>
          </div> -->
        <div class="report-container">
          <div class="report-detail">
            <table>
              <tr>
                <th>ID</th>
                <th>Customer Id</th>
                <th>Customer Name</th>
                <th>Customer Mobile Number</th>
                <th>Transaction Type</th>
                <th>Amount (SAR)</th>
                <th>Balance</th>
                <th>Date/Time</th>
                <th>Admin User Id</th>
              </tr>
              <tr v-for="(report, index) in reportList" :key="index">
                <td>{{ report.count }}</td>
                <td>{{ report.description.nameInEnglish }}</td>
                <td>{{ address(report.address.address) }}</td>
                <td>{{ report.totalBooking ? report.totalBooking : 0 }}</td>
                <td>
                  {{ report.vender && report.vender.firstName }}
                  {{ report.vender && report.vender.lastName }}
                </td>
                <td>{{ report.vender && report.vender.phone }}</td>
                <td>{{ report.vender && report.vender.email }}</td>
                <!-- <td>Lorem Ipsum</td> -->
              </tr>
              <tr>
                <td>1</td>
                <td>36</td>
                <td>Nowa Halt</td>
                <td>1284948827</td>
                <td>Addition</td>
                <td>200</td>
                <td>400 SAR</td>
                <td>6 April, 2023</td>
                <td>24</td>
              </tr>
            </table>
            <div class="bottom-container">
              <div>
                <p>
                  Showing
                  {{ pageSelected != 1 ? dataShow * pageSelected - dataShow : 1 }}
                  to
                  {{
                    reportList[reportList.length - 1] &&
                    reportList[reportList.length - 1].count
                  }}
                  of {{ reportData.length }} entries
                </p>
              </div>
              <div class="report-pagination">
                <paginate
                  v-model="pageSelected"
                  :page-range="3"
                  :margin-pages="2"
                  :page-count="pageCount"
                  :click-handler="clickCallback"
                  :prev-text="'Previous'"
                  :next-text="''"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
                ></paginate>
              </div>
            </div>
          </div>
        </div>
      </section>
    </default-layout>
  </template>
  
  <script>
  import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
  import Paginate from "vuejs-paginate-next";
  
  export default {
    name: "AllReports",
    components: {
      DefaultLayout,
      Paginate,
    },
    data() {
      return {
        addBalanceModel: false,
        reportData: [],
        reportList: [],
        //
        pageCount: 0,
        selectedReport: {},
        total: 0,
        pageSelected: 1,
        //
        dataShow: 10,
      };
    },
    methods: {
      clickCallback(num) {
        var copyFrom = num * this.dataShow - this.dataShow;
        var copyTo = num * this.dataShow;
        this.reportList = this.reportData.slice(copyFrom, copyTo);
      },
      addBalanceModelShow() {
        this.addBalanceModel = !this.addBalanceModel;
      },
      reSet() {
        this.pageSelected = 1;
        this.reportData = [];
        this.reportList = [];
      },
      async getRequests() {
        this.reSet();
      },
      close() {
        this.selectedReport = false;
        this.addBalanceModel = false;
        this.getRequests();
      },
    },
  };
  </script>
  <style scoped>
  .top-heading {
    line-height: 1.8;
  }
  .top-heading h2 {
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    font-size: 16px;
  }
  .top-heading p {
    text-align: left;
    letter-spacing: 0px;
    color: #808183;
    font-size: 12px;
  }
  .add-btn {
    display: flex;
    justify-content: flex-end;
  }
  .add-btn button {
    border: none;
    outline: none;
    border-radius: 11px;
    background: #febb12;
    color: #fff;
    padding: 12px 20px;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 2px 4px 1px #c9c9c9a6;
    width: 100%;
  }
  .report-detail {
    padding: 20px 0 40px 0;
  }
  .report-detail table {
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0px 0px 39px #00000012;
    border-radius: 11px;
    opacity: 1;
    background: #fff;
  }
  
  .report-detail table th {
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #eee;
    padding: 15px 5px;
    white-space: nowrap;
  }
  .report-detail table td {
    padding: 15px 5px;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    font-weight: normal;
    color: #9a9a9a;
    white-space: nowrap;
    font-size: 12px;
  }
  .profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .profile-image img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  .view-btn button {
    border: none;
    outline: none;
    border-radius: 7px;
    opacity: 1;
    background: #febb12;
    text-align: center;
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 12px;
    padding: 10px 50px;
    cursor: pointer;
  }
  .bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bottom-container div p {
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
    font-size: 12px;
  }
  
  .search-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    overflow: hidden;
    padding: 0 20px;
    margin-right: 10px;
    height: 46px;
  }
  
  .search-main {
    display: flex;
  }
  .search-container img {
    width: 15px;
  }
  .search-container input {
    outline: none;
    border: none;
    /* height: 38px; */
    color: #828282;
    width: 230px;
  }
  </style>
  