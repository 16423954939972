<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>
          <!-- {{
            $route.query.type.charAt(0).toUpperCase() +
            $route.query.type.slice(1)
          }} -->
          Cancelled Bookings
        </h2>
        <p>
          You can see all the
          <!-- {{
            $route.query.type.charAt(0).toUpperCase() +
            $route.query.type.slice(1)
          }} -->
          Cancelled Bookings here.
        </p>
      </div>
      <!-- <div class="add-btn">
        <button @click="DealModelShow">Add New Deal</button>
      </div> -->
      <div class="service-container">
        <div class="service-detail">
          <table>
            <tr>
              <th>ID</th>
              <th>Booking Name</th>
              <th>Amount</th>
              <th>Booking Date</th>
              <th>Cancelation Date</th>

              <th>Action</th>
            </tr>
            <tr v-for="(service, index) in serviceList" :key="index">
              <td>{{ service.count }}</td>
              <td>{{ service?.service?.name?.description?.nameInEnglish }}</td>
              <td>{{ service.amount ? service.amount : 0 }}</td>
              <td>
                {{
                  moment(service?.createdAt).format("ddd, D MMMM YYYY, h:mm A")
                }}
              </td>
              <td>
                {{
                  moment(service?.updatedAt).format("ddd, D MMMM YYYY, h:mm A")
                }}
              </td>

              <!-- <td>Lorem Ipsum</td> -->
              <td>
                <div class="view-btn" @click="servicesModelShow(service)">
                  <button>View</button>
                </div>
              </td>
            </tr>
            <!-- <tr>
              <td>02</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>

              <td>
                <div class="view-btn" @click="servicesModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>03</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>

              <td>
                <div class="view-btn" @click="servicesModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>04</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>

              <td>
                <div class="view-btn" @click="servicesModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>05</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>

              <td>
                <div class="view-btn" @click="servicesModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>06</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>

              <td>
                <div class="view-btn" @click="servicesModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>07</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>

              <td>
                <div class="view-btn" @click="servicesModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr>
            <tr>
              <td>08</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>
              <td>Lorem Ipsum</td>

              <td>
                <div class="view-btn" @click="servicesModelShow">
                  <button>Action</button>
                </div>
              </td>
            </tr> -->
          </table>
          <div class="bottom-container">
            <div>
              <p>
                Showing
                {{ pageSelected != 1 ? dataShow * pageSelected - dataShow : 1 }}
                to
                {{
                  serviceList[serviceList.length - 1] &&
                  serviceList[serviceList.length - 1].count
                }}
                of {{ serviceData.length }} entries
              </p>
            </div>
            <div class="service-pagination">
              <paginate
                v-model="pageSelected"
                :page-range="3"
                :margin-pages="2"
                :page-count="pageCount"
                :click-handler="clickCallback"
                :prev-text="'Previous'"
                :next-text="''"
                :container-class="'pagination'"
                :page-class="'page-item'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
      <CancelationRequestModel
        v-if="servicesModel"
        :service="selectedServices"
        @feedBack="feedBack"
        @call="close"
      />
      <FeedBack
        v-if="feedBackModel"
        :service="selectedServices"
        @modelClose="closeModel"
      />
    </section>
  </default-layout>
</template>

<script>
import moment from "moment";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import CancelationRequestModel from "@/components/Models/CancelationRequestModel.vue";
import FeedBack from "@/components/Models/FeedBackModel.vue";
import Paginate from "vuejs-paginate-next";

export default {
  name: "ServicesDetailsView",
  components: {
    DefaultLayout,
    Paginate,
    CancelationRequestModel,
    FeedBack,
  },
  data() {
    return {
      feedBackModel: false,
      servicesModel: false,
      serviceData: [],
      serviceList: [],
      //
      pageCount: 0,
      selectedService: {},
      total: 0,
      pageSelected: 1,
      //
      dataShow: 10,
    };
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    feedBack() {
      this.servicesModel = false;
      this.feedBackModel = true;
    },
    clickCallback(num) {
      var copyFrom = num * this.dataShow - this.dataShow;
      var copyTo = num * this.dataShow;
      this.serviceList = this.serviceData.slice(copyFrom, copyTo);
    },
    servicesModelShow(serviceObj) {
      this.selectedServices = serviceObj;
      this.servicesModel = !this.servicesModel;
    },
    reSet() {
      this.pageSelected = 1;
      this.serviceData = [];
      this.serviceList = [];
    },
    async getRequests(type) {
      this.reSet();
      let url = "";
      if (type == "approved") {
        url = "admin/approved-cancellation-requests";
      } else if (type == "rejected") {
        url = "admin/rejected-cancellation-requests";
      } else {
        url = "admin//cancellation-requests";
      }
      try {
        const services = await this.$axios.get(url);
        this.total = services.data.length;
        console.log(services.data);
        // asigning number
        for (let index = 0; index < services.data.length; index++) {
          const element = services.data[index];
          element.count = index + 1;
          this.serviceData.push(element);
        }
        this.pageCount = Math.ceil(this.total / this.dataShow);
        this.serviceList = this.serviceData.slice(0, this.dataShow);
      } catch (error) {
        console.log(error);
      }
    },
    closeModel() {
      this.feedBackModel = false;
    },
    close() {
      this.selectedService = false;
      this.servicesModel = false;
      var { type } = this.$route.query;
      this.getRequests(type);
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(val) {
        if (val.type == "pending") {
          this.getRequests("pending");
        } else if (val.type == "approved") {
          this.getRequests("approved");
        } else if (val.type == "rejected") {
          this.getRequests("rejected");
        }
      },
    },
  },
};
</script>
<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
}
.service-detail {
  padding: 20px 0 40px 0;
}
.service-detail table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0px 0px 39px #00000012;
  border-radius: 11px;
  opacity: 1;
  background: #fff;
}

.service-detail table th {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding: 15px 5px;
  white-space: nowrap;
}
.service-detail table td {
  padding: 15px 5px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-weight: normal;
  color: #9a9a9a;
  white-space: nowrap;
  font-size: 12px;
}
.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.view-btn button {
  border: none;
  outline: none;
  border-radius: 7px;
  opacity: 1;
  background: #febb12;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 50px;
  cursor: pointer;
}
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-container div p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 12px;
}
</style>
