<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Reviews List</h2>
        <p>You can manage the service reviews here.</p>
      </div>
      <!-- <div class="add-btn">
              <button @click="DealModelShow">Add New Deal</button>
            </div> -->
      <br />
      <div class="service-info">
        <div style="text-align: left">
          <p>
            <b>Service Name</b>:
            {{ service_detail?.description?.nameInEnglish }}
          </p>
        </div>
        <div style="text-align: right">
          <p><b>Vendor Name</b>: {{ service_detail?.vender?.name }}</p>
          <br />
          <p>
            <b>Vendor Mobile Number</b>: {{ service_detail?.vender?.phone }}
          </p>
        </div>
      </div>
      <br />
      <div
        v-for="review in reviews"
        :key="review._id"
        class="main-banner-editor"
      >
        <div>
          <h3>Customer Name: {{ review?.name }}</h3>
          <br />
          <p>
            {{ moment(review?.createdAt).format("ddd, D MMMM YYYY, h:mm A") }}
          </p>
          <br />
          <div style="display: flex; justify-content: space-between">
            <p>{{ review?.comment }}</p>
            <p><b>Rating</b>: {{ review?.rating }}</p>
          </div>
        </div>
        <button class="delete-btn" @click="Delete(review._id)">Delete</button>
      </div>
      <div v-if="reviews.length == 0"><p>No Review Yet!</p></div>

      <br />
      <br />
      <br />
      <br />
    </section>
  </default-layout>
</template>

<script>
import moment from "moment";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  name: "ServiceReviews",
  components: {
    DefaultLayout,
  },
  data() {
    return {
      service_id: "",
      service_detail: {},
      reviews: [],
      addBannerModel: false,
      editBannerModel: false,
      selectedBanner: {},
      mainBanner: [],
      smallBanner: [],
      review_del_id: "",
      banner_type: "",
    };
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    clickCallback(num) {
      var copyFrom = num * this.dataShow - this.dataShow;
      var copyTo = num * this.dataShow;
      this.homepageList = this.homepageData.slice(copyFrom, copyTo);
    },
    async getRequests() {
      this.$axios.get(`admin/get-review/${this.service_id}`).then((res) => {
        this.reviews = res.data;
        console.log(res.data);
      });
      this.$axios.get(`services/${this.service_id}`).then((res) => {
        this.service_detail = res.data;
        console.log(res.data);
      });
    },
    close() {
      this.selectedHomepage = false;
      this.addBannerModel = false;
      this.getRequests();
    },
    Delete(id) {
      const imagePath = require("../assets/images/cancelicon.png");
      this.review_del_id = id;
      this.$swal({
        title: "You want to Delete?",
        text: "You want to Delete the Review?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.publishedConfirmed();
          console.log("result");
        }
      });
    },
    async publishedConfirmed() {
      try {
        var res = await this.$axios.put(
          `admin/delete-review/${this.service_id}/${this.review_del_id}`
        );
        if (res) {
          this.$emit("call");
          this.getRequests();
          this.$swal({
            title: "Deleted",
            text: "The Review is Deleted successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#FEBB12",
            confirmButtonText: "Done",
            reverseButtons: true,
          });
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Some Thing Went Wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
  },
  watch: {
    "$route.params": {
      immediate: true,
      handler({ id }) {
        this.service_id = id;
      },
    },
  },
};
</script>
<style scoped>
.service-info {
  display: flex;
  justify-content: space-between;
}
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 160px;
}

.main-banner-editor {
  position: relative;
  text-align: left;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-top: 20px;
}

.main-banner-editor-content {
  display: flex;
  justify-content: left;
}

.main-banner-editor-content div {
  width: 50%;
}

.banner-image {
  width: 90% !important;
  height: 250px;
}

.banner-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.delete-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  background: #febb12;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.edit-btn {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 60px;
  background: #febb12;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
