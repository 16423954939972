<template>
    <section class="login-signup">
      <div class="primary-login">
        <div class="close-icon" @click="closeSlide">
          <img src="../../assets/images/close-icon.svg" alt />
        </div>
        <div class="service-heading">
          <h2>Edit Banner</h2>
        </div>
        <form @submit="addNewCategory">
          <div class="category-content">
            <div class="img-upload-section">
              <div class="upload-file">
                <p>Upload Website Image</p>
                <div v-if="websiteImagePhotoUrl != ''" class="icon">
                  <img class="hotel-icon" :src="websiteImagePhotoUrl" />
                </div>
                <label
                  for="websiteImageinput"
                  :class="{ active: err.websiteImageFile }"
                >
                  Add image
                  <input
                    id="websiteImageinput"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    @change="handleWebsiteImageFile"
                  />
                </label>
              </div>
            </div>
  
            <div class="img-upload-section">
              <div class="upload-file">
                <p>Upload Mobile App Image</p>
                <div v-if="mobileAppImagePhotoUrl != ''" class="icon">
                  <img class="hotel-icon" :src="mobileAppImagePhotoUrl" />
                </div>
                <label
                  for="mobileAppImageinput"
                  :class="{ active: err.mobileAppImageFile }"
                >
                  Add image
                  <input
                    id="mobileAppImageinput"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    @change="handleMobileAppImageFile"
                  />
                </label>
              </div>
            </div>
  
            <input type="text" placeholder="Add Link" v-model="link" required />
  
            <div class="add-btn">
              <button type="submit" :disabled="loading">
                {{ !loading ? "Save" : "Loading..." }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "editBannerModel",
    props: ['banner'],
    data() {
      return {
        websiteImageFile: null,
        websiteImagePhotoUrl: this.banner?.websiteImage,
        mobileAppImageFile: null,
        mobileAppImagePhotoUrl: this.banner?.mobileImage,
        link: this.banner?.link,
        url: "",
        //
        err: {},
        loading: false,
      };
    },
    methods: {
      async addNewCategory(e) {
        e.preventDefault();
        this.loading = true;
        this.err.websiteImageFile = false;
        this.err.mobileAppImageFile = false;
        var websiteImagePhotoUrl = await this.uploadwebsiteImageFile();
        var mobileAppImagePhotoUrl = await this.uploadmobileAppImageFile();
        this.add(websiteImagePhotoUrl, mobileAppImagePhotoUrl);
      },
      async add(websiteImagePhotoUrl, mobileAppImagePhotoUrl) {
        try {
          var res = this.$axios.put(`admin/banner/${this.banner?._id}`, {
            link: this.link,
            websiteImage : websiteImagePhotoUrl,
            mobileImage: mobileAppImagePhotoUrl,
          });
          if (res) {
            this.loading = false;
            this.$swal({
              icon: "success",
              title: "Banner Updated!",
              showConfirmButton: false,
              timer: 3000,
            });
            this.closeSlide();
            this.$emit("call");
          }
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      },
      async uploadwebsiteImageFile() {
        try {
          const imagesData = await this.$axios.post("user/upload", this.websiteImageFile, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          return imagesData.data.url;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      },
      async uploadmobileAppImageFile() {
        try {
          const imagesData = await this.$axios.post("user/upload", this.mobileAppImageFile, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          return imagesData.data.url;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
      },
      closeSlide() {
        this.$parent.$parent.editBannerModel = false;
      },
      handleWebsiteImageFile(event) {
        let formData = new FormData();
        formData.append("image", event.target.files[0]);
        this.websiteImageFile = formData;
        this.websiteImagePhotoUrl = URL.createObjectURL(event.target.files[0]);
        console.log(this.websiteImageFile);
        console.log(this.websiteImagePhotoUrl);
      },
      handleMobileAppImageFile(event) {
        let formData = new FormData();
        formData.append("image", event.target.files[0]);
        this.mobileAppImageFile = formData;
        this.mobileAppImagePhotoUrl = URL.createObjectURL(event.target.files[0]);
        console.log(this.mobileAppImageFile);
        console.log(this.mobileAppImagePhotoUrl);
      },
    },
  };
  </script>
  
  <style scoped>
  .img-upload-section {
    text-align: left;
  }
  .login-signup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #0000004d;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  .primary-login {
    width: 450px;
    height: 80vh;
    background: #fff;
    padding: 25px;
    border-radius: 20px;
    position: relative;
    overflow: scroll;
  }
  .primary-login::-webkit-scrollbar {
    display: none;
  }
  .primary-login {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .close-icon {
    cursor: pointer;
    top: 26px;
    position: absolute;
    right: 20px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    z-index: 99;
  }
  .service-heading h2 {
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.8;
    font-size: 18px;
  }
  .category-content {
    padding: 0 50px;
  }
  .category-content input {
    outline: none;
    font-size: 12px;
    padding: 18px 20px;
    border-radius: 50px;
    border: none;
    box-shadow: 0px 0px 8px 2px #e9e8e8;
    color: #c4c4c4;
    width: 90%;
    margin: 20px 0 8px 0;
  }
  
  .category-content select {
    outline: none;
    font-size: 12px;
    padding: 18px 20px;
    border-radius: 50px;
    border: none;
    box-shadow: 0px 0px 8px 2px #e9e8e8;
    color: #c4c4c4;
    width: 100%;
    margin: 20px 0 8px 0;
  }
  
  .category-content textarea {
    outline: none;
    font-size: 12px;
    padding: 14px 20px;
    border-radius: 50px;
    border: none;
    box-shadow: 0px 0px 8px 2px #e9e8e8;
    color: #c4c4c4;
    width: 90%;
    margin: 20px 0 8px 0;
  }
  .category-content textarea {
    height: 123px;
    border-radius: 20px;
  }
  .upload-file input {
    display: none;
  }
  .upload-file {
    margin: 30px 0px 10px 0px;
    padding: 15px;
    box-shadow: 0px 0px 8px 2px #e9e8e8;
    border-radius: 20px;
    line-height: 1.8;
  }
  .upload-file p {
    letter-spacing: 0px;
    color: #393f45;
    font-size: 12px;
    padding-bottom: 20px;
    line-height: 1.8;
  }
  .upload-file label {
    cursor: pointer;
    padding: 10px 24px;
    background: #febb12;
    color: #fff;
    font-size: 12px;
    border-radius: 20px;
    box-shadow: 0 2px 4px 1px #c9c9c9a6;
  }
  .upload-file label.active {
    border: 1px solid red !important;
  }
  .add-btn {
    margin: 40px 0 10px 0;
  }
  .add-btn button {
    border: none;
    outline: none;
    border-radius: 32px;
    background: #febb12;
    color: #fff;
    padding: 15px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    box-shadow: 0 2px 4px 1px #c9c9c9a6;
  }
  
  .icon {
    margin-bottom: 20px;
    width: 100%;
    height: 100px;
    border-radius: 10px;
    background: #f0f2f7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  /* responsive */
  @media (max-width: 479px) and (min-width: 320px) {
    .primary-login {
      width: 84%;
    }
  }
  </style>
  