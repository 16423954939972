<template>
  <div class="image-container">
    <div class="profile-avatar">
      <img
      @click="() => $router.push(`/profile`)"
        :src="
          $store.state?.user?.image
            ? $store.state?.user?.image
            : placeholder
        "
        alt="user-icon"
      />
    </div>
    <div class="profile-primary">
      <h5 @click="() => $router.push(`/profile`)">
        {{
          $store.state?.user?.firstName
            ? "Hello, " + $store.state.user.firstName
            : "Hello, George"
        }}
      </h5>
      <h6>Admin</h6>
    </div>
  </div>
</template>
<script>
export default {
  name: "profileAvatar",
  data(){
    const placeholder = require("../../assets/images/user-icon.svg");
    return {
      placeholder,
    }
  }
};
</script>
<style scoped>
.image-container {
  display: flex;
  align-items: center;
  margin-right: 34px;
}
.image-container .profile-avatar {
  width: 34px;
  height: 34px;
  background: #eee;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}
.profile-primary h5 {
  text-align: left;
  line-height: 1.4;
  font-size: 14px;
  cursor: pointer;
}
.profile-primary h6 {
  text-align: left;
  font-size: 12px;
  color: #b4b7b9;
}
</style>
>
