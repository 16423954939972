<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Categories</h2>
        <p>
          You can see all the categories here. Also you can add new categories.
        </p>
      </div>
      <div class="add-btn">
        <!-- <button @click="CategoryeModelShow">Add New Category</button> -->
      </div>
      <div class="category-main">
        <div
          class="category-card"
          v-for="(cat, index) in categoryData"
          :key="index"
        >
          <div class="icon">
            <img class="hotel-icon" :src="cat.image" />
          </div>
          <div class="cat-desc">
            <h6>{{ cat.category }}</h6>
            <label v-if="isSuperAdmin || category_permissions?.permissions?.edit" class="switch">
              <input
                type="checkbox"
                :checked="cat.status == 'active' ? true : false"
                @click="(e) => toggleActive(e, cat._id)"
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div v-if="isSuperAdmin || category_permissions?.permissions?.edit" class="view-icn">
            <img @click="editCategory(cat)" src="../assets/images/edit.svg" />
          </div>
          <!-- <div class="bottom-icons">
          <div class="cat-desc">
            <h6>{{ cat.category }}</h6>
            <label class="switch">
              <input
                type="checkbox"
                :checked="cat.status == 'active' ? true : false"
                @click="(e) => toggleActive(e, cat._id)"
              />
              <span class="slider round"></span>
            </label>
          </div>
         <div class="bottom-icons">
            <img src="../assets/images/edit.svg" @click="editCategory(cat)" />
            <img
              src="../assets/images/delete.svg"
              @click="deleteCategory(cat._id)"
            />
          </div> -->
        </div>
        <!-- <div class="category-card">
          <div class="icon">
            <img src="../assets/images/apartment.svg" />
          </div>
          <h6>Appartments</h6>
          <div class="bottom-icons">
            <img src="../assets/images/edit.svg" />
            <img src="../assets/images/delete.svg" />
          </div>
        </div>
        <div class="category-card">
          <div class="icon">
            <img src="../assets/images/resort.svg" />
          </div>
          <h6>Resorts</h6>
          <div class="bottom-icons">
            <img src="../assets/images/edit.svg" />
            <img src="../assets/images/delete.svg" />
          </div>
        </div>
        <div class="category-card">
          <div class="icon">
            <img src="../assets/images/forms.svg" />
          </div>
          <h6>Farms</h6>
          <div class="bottom-icons">
            <img src="../assets/images/edit.svg" />
            <img src="../assets/images/delete.svg" />
          </div>
        </div>
        <div class="category-card">
          <div class="icon">
            <img src="../assets/images/lounges.svg" />
          </div>
          <h6>Lounges</h6>
          <div class="bottom-icons">
            <img src="../assets/images/edit.svg" />
            <img src="../assets/images/delete.svg" />
          </div>
        </div>
        <div class="category-card">
          <div class="icon">
            <img src="../assets/images/staduim.svg" />
          </div>
          <h6>Staduims</h6>
          <div class="bottom-icons">
            <img src="../assets/images/edit.svg" />
            <img src="../assets/images/delete.svg" />
          </div>
        </div>
        <div class="category-card">
          <div class="icon">
            <img src="../assets/images/chalets.svg" />
          </div>
          <h6>Chalets</h6>
          <div class="bottom-icons">
            <img src="../assets/images/edit.svg" />
            <img src="../assets/images/delete.svg" />
          </div>
        </div>
        <div class="category-card">
          <div class="icon">
            <img src="../assets/images/camps.svg" />
          </div>
          <h6>Camps</h6>
          <div class="bottom-icons">
            <img src="../assets/images/edit.svg" />
            <img src="../assets/images/delete.svg" />
          </div>
        </div>
        <div class="category-card">
          <div class="icon">
            <img src="../assets/images/hall.svg" />
          </div>
          <h6>Wedding Halls</h6>
          <div class="bottom-icons">
            <img src="../assets/images/edit.svg" />
            <img src="../assets/images/delete.svg" />
          </div>
        </div> -->
      </div>
      <CategoriesModel v-if="categoryModel" @reCall="getData" />
      <EditModel v-if="editModel" ref="edit" @reCall="getData" />
    </section>
  </default-layout>
</template>

<script>
import CategoriesModel from "@/components/Models/ModelCategories.vue";
import EditModel from "@/components/Models/EditModel.vue";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  name: "CatView",
  components: {
    DefaultLayout,
    CategoriesModel,
    EditModel,
  },
  data() {
    return {
      isSuperAdmin: false,
      category_permissions: "",
      categoryData: [],
      categoryModel: false,
      editModel: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        var res = await this.$axios.get("admin/all-category");
        if (res) {
          this.categoryData = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    editCategory(val) {
      this.editModel = true;
      setTimeout(() => {
        this.$refs.edit.dataEdit = val;
      }, 200);
    },
    CategoryeModelShow() {
      this.categoryModel = !this.categoryModel;
    },
    async confirmDelete(id) {
      try {
        var res = await this.$axios.delete(`admin/category/${id}`);
        if (res) {
          this.$swal({
            icon: "success",
            title: "Deleted",
            showConfirmButton: false,
            timer: 3000,
          });
          this.getData();
        }
      } catch (error) {
        console.log(error);
      }
    },
    deleteCategory(categoryId) {
      const imagePath = require("../assets/images/cancelicon.png");
      this.$swal({
        title: "You want to delete category?",
        text: "You want to delete category?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmDelete(categoryId);
        }
      });
    },
    async toggleActive(e, id) {
      this.$swal({
        toast: true,
        title: "Category Status Updated!",
        icon: "success",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
      });
      if (e.target.checked) {
        await this.$axios.put(`admin/active-category/${id}`);
      } else {
        await this.$axios.put(`admin/inactive-category/${id}`);
      }
    },
    setPermissions() {
      let permissions = this.$store.state?.user?.resources;

      this.isSuperAdmin =
        this.$store.state?.user?.role === "SuperAdmin" ? true : false;

      this.category_permissions = permissions?.find(
        (permission) => permission.name == "category"
      );
    },
  },

  watch: {
    "$store.state.user": {
      handler() {
        this.setPermissions();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
}
.category-main {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
  /* justify-content: space-between; */
  padding: 20px 0;
}
.category-card {
  position: relative;
  width: 20%;
  margin: 0 0 -12px 0px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.category-card .view-icn {
  position: absolute;
  right: 10px;
  top: 10px;
}
.category-card .view-icn img {
  width: 40px;
  height: 40px;
}
.category-card .icon {
  width: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover !important;
}
.category-card .icon .hotel-icon {
  width: 100% !important;
}
.category-card .icon img {
  width: 60px;
  height: 85px;
  object-fit: cover;
}
.category-card h6 {
  letter-spacing: 0px;
  color: #6a6a6a;
  font-size: 14px;
  text-align: center;
  padding: 15px 0 10px 0;
  font-weight: normal;
}
.bottom-icons {
  display: flex;
  justify-content: space-between;
}
.bottom-icons img {
  width: 50px;
  margin: 0 5px;
}
.cat-desc {
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #febb12;
}

input:focus + .slider {
  box-shadow: 0 0 1px #febb12;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
