export const CategoryType = [
  { key: "Chalets", value: "Chalets" },
  { key: "Resorts", value: "Resorts" },
  { key: "Stadium", value: "Stadium" },
  { key: "Apartment", value: "Apartment" },
  { key: "Lounges", value: "Lounges" },
  { key: "Camps", value: "Camps" },
  { key: "Wedding_Halls", value: "Wedding Halls" },
  { key: "Farms", value: "Farms" },
  { key: "Hotel", value: "Hotel" },
];
