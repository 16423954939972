<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>{{ $route.params.cat }}</h2>
        <p>You can see all the {{ $route.params.cat }} bookings here.</p>
      </div>
      <!-- <div class="add-btn">
        <button @click="DealModelShow">Add New Deal</button>
      </div> -->
      <div class="service-container">
        <div class="service-detail">
          <table>
            <tr>
              <th>Serial no.</th>
              <th>Booking ID</th>
              <th>Customer Name</th>
              <th>Mobile</th>

              <th>Service name</th>
              <!-- <th>Service address</th> -->
              <th>Vendor name</th>
              <th>Check In</th>
              <th>Action</th>
            </tr>
            <tr v-for="(booking, index) in bookingList" :key="index">
              <td>{{ booking.count }}</td>
              <td>{{ booking?.bookingId }}</td>
              <td>
                {{ booking?.user?.firstName }} {{ booking?.user?.lastName }}
              </td>
              <td>{{ booking?.user?.phone }}</td>

              <td>{{ booking?.service?.name?.description?.nameInEnglish }}</td>
              <!-- <td>
                {{
                  booking?.service?.name?.address?.address?.substring(0, 20) +
                  "..."
                }}
              </td> -->
              <td>
                {{ booking?.vender?.firstName }} {{ booking?.vender?.lastName }}
              </td>
              <td>
                {{
                  moment(booking?.checkIn).format("ddd, D MMMM YYYY, h:mm A")
                }}
              </td>
              <td>
                <div class="action-btns">
                  <!-- <img
                    v-if="new Date() < new Date(Date.parse(booking?.checkIn))"
                    @click="cancelBooking(booking._id)"
                    class="eye-icon"
                    src="../assets/images/cancel.svg"
                    style="height: 16px"
                  /> -->
                  <button
                    v-if="new Date() < new Date(Date.parse(booking?.checkIn))"
                    style="
                      width: fit-content !important;
                      padding: 10px 20px !important;
                    "
                    @click="cancelBooking(booking._id)"
                  >
                    Cancel
                  </button>
                  <button @click="bookingModelShow(booking)">View</button>
                </div>
              </td>
            </tr>
          </table>
          <div class="bottom-container">
            <div>
              <p>
                Showing
                {{ pageSelected != 1 ? dataShow * pageSelected - dataShow : 1 }}
                to
                {{
                  bookingList[bookingList.length - 1] &&
                  bookingList[bookingList.length - 1].count
                }}
                of {{ bookingData.length }} entries
              </p>
            </div>
            <div class="service-pagination">
              <paginate
                v-model="pageSelected"
                :page-range="3"
                :margin-pages="2"
                :page-count="pageCount"
                :click-handler="clickCallback"
                :prev-text="'Previous'"
                :next-text="''"
                :container-class="'pagination'"
                :page-class="'page-item'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
      <BookingModel
        v-if="bookingModel"
        :bookingDetailsData="bookingDetailsData"
      />
    </section>
  </default-layout>
</template>

<script>
import moment from "moment";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import BookingModel from "@/components/Models/BookingModel.vue";
import Paginate from "vuejs-paginate-next";

export default {
  name: "DetailsView",
  components: {
    DefaultLayout,
    Paginate,
    BookingModel,
  },
  data() {
    return {
      bookingModel: false,
      bookingList: [],
      bookingData: [],
      bookingDetailsData: {},
      //
      pageCount: 0,
      selectedBooking: {},
      total: 0,
      pageSelected: 1,
      //
      dataShow: 10,
    };
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    async getRequests() {
      try {
        const bookings = await this.$axios.get("admin/bookings-by-category");
        const booking_list = bookings.data.find(
          (bookings) => bookings.name == this.$route.params.cat
        ).bookings;

        this.total = booking_list.length;
        //asigning number
        this.bookingData = [];
        for (let index = 0; index < booking_list.length; index++) {
          const element = booking_list[index];
          element.count = index + 1;
          this.bookingData.push(element);
        }
        this.pageCount = Math.ceil(this.total / this.dataShow);
        this.bookingList = this.bookingData.slice(0, this.dataShow);

        console.log(this.bookingList);
      } catch (error) {
        console.log(error);
      }
    },
    bookingModelShow(booking) {
      this.bookingDetailsData = booking;
      this.bookingModel = !this.bookingModel;
    },
    clickCallback(num) {
      var copyFrom = num * this.dataShow - this.dataShow;
      var copyTo = num * this.dataShow;
      this.bookingList = this.bookingData.slice(copyFrom, copyTo);
    },
    cancelBooking(id) {
      const imagePath = require("../assets/images/cancelicon.png");
      this.$swal({
        title: "You want to Cancel?",
        text: "You want to Cancel the Booking?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelConfirmed(id);
        }
      });
    },
    async cancelConfirmed(id) {
      try {
        var res = await this.$axios.put(
          `admin/cancel-booking/${id}?type=balance`
        );
        if (res) {
          this.$swal({
            title: "Booking Cancelled",
            text: "Booking Cancelled successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#FEBB12",
            confirmButtonText: "Done",
            reverseButtons: true,
          });
          this.getRequests();
        }
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Some Thing Went Wrong!",
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 18px;
  text-transform: capitalize;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
}
.service-detail {
  padding: 20px 0 40px 0;
  overflow-x: scroll;
}
.service-detail table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0px 0px 39px #00000012;
  border-radius: 11px;
  opacity: 1;
  background: #fff;
}

.service-detail table th {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding: 15px 5px;
  white-space: nowrap;
}
.service-detail table td {
  padding: 15px 5px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-weight: normal;
  color: #9a9a9a;
  white-space: nowrap;
  font-size: 12px;
}
.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.action-btns button {
  border: none;
  outline: none;
  border-radius: 7px;
  opacity: 1;
  background: #febb12;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 50px;
  cursor: pointer;
}
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-container div p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 12px;
}
.action-btns {
  max-width: 250px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 20px;
  gap: 10px;
}

.action-btns img {
  width: 40px;
  cursor: pointer;
}
</style>
