<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="user-profile">
        <Profile />
      </div>
      <div class="top-heading">
        <h2>Users</h2>
        <p>You can see all of the users here.</p>
      </div>
    </section>
    <!-- app users -->
    <section class="main-wrapper">
      <!-- <div class="top-heading">
        <h2>Users</h2>
        <p>You can see all of the users here.</p>
      </div> -->
      <div class="add-btn">
        <div>
          <h2 v-if="type === 'user'">App Users</h2>
          <h2 v-if="type === 'admin'">Admin Users</h2>
        </div>
        <div class="search-main">
          <div class="search-container">
            <input v-model="search" type="text" placeholder="Search here..." />
            <img src="../assets/images/search.svg" />
          </div>
          <button
            v-if="isSuperAdmin || user_permissions?.permissions?.create"
            @click="createUser()"
            style="margin-right: 10px"
          >
            Add User
          </button>
        </div>
      </div>
      <div class="user-container">
        <div class="user-detail">
          <table>
            <tr>
              <th>Serial No.</th>
              <th>User ID</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th v-if="isSuperAdmin || user_permissions?.permissions?.edit">
                Status
              </th>
              <th>Action</th>
            </tr>
            <tr v-for="(user, index) in userList" :key="index">
              <td>{{ user.count }}</td>
              <td>{{ user.userId }}</td>
              <td>{{ `${user.firstName ?? ""} ${user.lastName ?? ""}` }}</td>
              <td>{{ `${user.email ? user.email : "NA"}` }}</td>
              <td>{{ user.phone ?? "NA" }}</td>
              <td v-if="isSuperAdmin || user_permissions?.permissions?.edit">
                <label class="switch">
                  <input
                    type="checkbox"
                    :checked="!user.isDeleted"
                    @click="(e) => toggleActive(user)"
                  />
                  <span class="slider round"></span>
                </label>
              </td>
              <td>
                <div class="action-btns">
                  <img
                    v-if="
                      user.role == 'Admin' &&
                      (isSuperAdmin || user_permissions?.permissions?.edit)
                    "
                    @click="() => $router.push(`/permissions/${user._id}`)"
                    class="key_icn"
                    src="../assets/images/key.svg"
                  />
                  <img
                    @click="showUserModal(user)"
                    class="eye-icon"
                    src="../assets/images/eye.svg"
                  />
                  <img
                    v-if="isSuperAdmin || user_permissions?.permissions?.edit"
                    @click="showEditUserModal(user)"
                    src="../assets/images/edit.svg"
                  />
                </div>
              </td>
            </tr>
          </table>
          <div class="bottom-container">
            <div>
              <p>
                Showing
                {{ pageSelected != 1 ? dataShow * pageSelected - dataShow : 1 }}
                to
                {{
                  userList[userList.length - 1] &&
                  userList[userList.length - 1].count
                }}
                of {{ userData.length }} entries
              </p>
            </div>
            <div class="service-pagination">
              <paginate
                v-model="pageSelected"
                :page-range="3"
                :margin-pages="2"
                :page-count="pageCount"
                :click-handler="clickCallback"
                :prev-text="'Previous'"
                :next-text="''"
                :container-class="'pagination'"
                :page-class="'page-item'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
      <AdminModel v-if="adminModel" :user="selectedUser" />
      <AppUserModel v-if="userModel" :user="selectedUser" />
      <CreateUserModel
        v-if="createModel"
        @close="close"
        :user="selectedUser"
        :mode="mode"
        :role="type"
      />
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import AdminModel from "@/components/Models/AdminModel.vue";
import AppUserModel from "@/components/Models/User/AppUserModel.vue";
import CreateUserModel from "@/components/Models/User/CreateUserModel.vue";
import Paginate from "vuejs-paginate-next";
import Profile from "@/components/common/Profile.vue";
import { UserType } from "@/constants/UserType";

export default {
  name: "UsersView",
  components: {
    Profile,
    DefaultLayout,
    Paginate,
    AdminModel,
    AppUserModel,
    CreateUserModel,
  },
  data() {
    // conso
    return {
      isSuperAdmin: false,
      user_permissions: "",
      allData: [],
      userData: [],
      userList: [],
      type: "user",
      //
      pageCount: 0,
      selectedService: {},
      total: 0,
      pageSelected: 1,
      //
      dataShow: 10,
      userTypes: UserType,
      //
      createModel: false,
      userModel: false,
      adminModel: false,
      selectedUser: null,
      mode: "create",
      //
      search: "",
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async toggleActive(user) {
      if (!user.isDeleted) {
        await this.$axios.put(`admin/active/${user._id}`);
      } else {
        await this.$axios.put(`admin/inactive/${user._id}`);
      }
      this.$swal({
        toast: true,
        title: "User Status Updated!",
        icon: "success",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    pushData(val) {
      val.count = this.userData.length + 1;
      this.userData.push(val);
      this.clickCallback(this.pageSelected);
      this.pageCount = Math.ceil(this.userData.length / this.dataShow);
    },
    deleteUser(dealId) {
      const imagePath = require("../assets/images/cancelicon.png");
      this.$swal({
        title: "You want to delete user?",
        text: "You want to delete user?",
        imageUrl: imagePath,
        imageWidth: 100,
        imageHeight: 100,
        showCancelButton: true,
        confirmButtonColor: "#FEBB12",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmDelete(dealId);
        }
      });
    },
    async confirmDelete(id) {
      try {
        var res = await this.$axios.delete(`admin/user/${id}`);
        if (res) {
          this.$swal({
            icon: "success",
            title: "Deleted",
            showConfirmButton: false,
            timer: 3000,
          });
          setTimeout(() => {
            this.userList = this.userList.filter((e) => {
              return e._id != id;
            });
            this.userData = this.userData.filter((e) => {
              return e._id != id;
            });
            this.pageCount = Math.ceil(this.userData.length / this.dataShow);
            if (this.userList && !this.userList.length) {
              if (this.pageSelected > 1) {
                this.pageSelected = this.pageSelected - 1;
                this.clickCallback(this.pageSelected);
              }
            }
          }, 3000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    getFormatedDate(dt) {
      var date = new Date(dt);
      var year = date.toLocaleString("default", { year: "numeric" });
      var month = date.toLocaleString("default", { month: "2-digit" });
      var day = date.toLocaleString("default", { day: "2-digit" });
      var formattedDate = year + "-" + month + "-" + day;
      return formattedDate;
    },
    getDate(val) {
      var d = new Date(val);
      return d.toLocaleDateString("en-GB");
    },
    clickCallback(num) {
      var copyFrom = num * this.dataShow - this.dataShow;
      var copyTo = num * this.dataShow;
      this.userList = this.userData.slice(copyFrom, copyTo);
    },
    address(add) {
      let address = "";
      if (add.length > 20) {
        address = add.slice(0, 20) + "...";
      } else {
        address = add;
      }
      return address;
    },
    showUserModal(user) {
      this.selectedUser = user;
      if (this.type === "user") {
        this.userModel = !this.userModel;
      } else {
        this.adminModel = !this.adminModel;
      }
    },
    showEditUserModal(user) {
      this.mode = "edit";
      this.selectedUser = user;
      this.createModel = !this.createModel;
    },
    createUser() {
      this.mode = "create";
      this.createModel = true;
    },
    reset() {
      this.pageSelected = 1;
      this.userList = [];
    },
    close() {
      this.createModel = false;
      this.getUsers(this.type);
    },
    async getUsers(role) {
      this.reset();

      try {
        let usersList = [];
        if (role === "user") {
          let { data: users } = await this.$axios.get(`admin/app-user`);
          usersList = users;
        }
        if (role === "admin") {
          let { data: users } = await this.$axios.get(`admin/admin-user`);
          usersList = users;
        }

        this.total = usersList.length;
        for (let index = 0; index < usersList.length; index++) {
          const element = usersList[index];
          element.count = index + 1;
        }
        this.pageCount = Math.ceil(this.total / this.dataShow);
        this.allData = [...usersList];
        this.userData = usersList;
        this.userList = usersList.slice(0, this.dataShow);
      } catch (error) {
        console.log(error);
      }
    },
    setPermissions() {
      let permissions = this.$store.state?.user?.resources;

      this.isSuperAdmin =
        this.$store.state?.user?.role === "SuperAdmin" ? true : false;

      this.user_permissions = permissions?.find(
        (permission) => permission.name == "user"
      );
    },
  },
  watch: {
    "$store.state.user": {
      handler() {
        this.setPermissions();
      },
      immediate: true,
    },
    "$route.query": {
      immediate: true,
      handler(val) {
        this.type = val.role;
        this.getUsers(val.role);
      },
    },
    mode: function (newVal) {
      // watch it
      if (newVal) {
        this.addUser = {};
      }
    },
    search: function (newVal) {
      const allData = JSON.parse(JSON.stringify(this.allData));
      let filterResults = allData.filter((i) => {
        let result = false;
        if (i.firstName) {
          result =
            result ||
            i.firstName.toLowerCase().match(new RegExp(newVal.toLowerCase()));
        }
        if (i.lastName) {
          result =
            result ||
            i.lastName.toLowerCase().match(new RegExp(newVal.toLowerCase()));
        }
        if (i.email) {
          result =
            result ||
            i.email.toLowerCase().match(new RegExp(newVal.toLowerCase()));
        }
        if (i.phone) {
          result = result || String(i.phone).match(new RegExp(newVal));
        }
        return result;
      });

      if (newVal === "") {
        filterResults = allData;
      }
      this.userData = filterResults;

      this.total = filterResults.length;
      for (let index = 0; index < filterResults.length; index++) {
        const element = filterResults[index];
        element.count = index + 1;
      }
      this.pageCount = Math.ceil(this.total / this.dataShow);
      this.userList = filterResults.slice(0, this.dataShow);

      console.log(newVal);
    },
  },
};
</script>
<style scoped>
.action-btns {
  max-width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-btns img {
  width: 40px;
  cursor: pointer;
}

.key_icn {
  width: 15px;
  height: 15px;
  padding-left: 20px;
  padding-right: 20px;
}
.user-profile {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.icon-deals img {
  width: 40px;
  cursor: pointer;
}
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.add-btn a {
  text-decoration: none;
  color: #fff;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 11px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  min-width: 148px;
}
.user-detail {
  padding: 20px 0 40px 0;
}
.user-detail table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0px 0px 39px #00000012;
  border-radius: 11px;
  opacity: 1;
  background: #fff;
}

.user-detail table th {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding: 15px;
}
.user-detail table td {
  padding: 15px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-weight: normal;
  color: #9a9a9a;
  /* opacity: 0.7; */
  font-size: 12px;
}
.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.view-btn button {
  border: none;
  outline: none;
  border-radius: 7px;
  opacity: 1;
  background: #febb12;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 50px;
  cursor: pointer;
}
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-container div p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 12px;
}
.add-btn {
  justify-content: space-between;
  align-items: center;
}
.add-btn h2 {
  font-size: 16px;
}
.search-container {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 0 20px;
  margin-right: 10px;
  height: 46px;
}
.search-main {
  display: flex;
}
.search-container img {
  width: 15px;
}
.search-container input {
  outline: none;
  border: none;
  /* height: 38px; */
  color: #828282;
  width: 230px;
}
.select-type {
  color: #828282;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 10px;
}
select {
  outline: none;
  border: none;
  color: #828282;
  padding: 0 5px;
  font-size: 12px;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #febb12;
}

input:focus + .slider {
  box-shadow: 0 0 1px #febb12;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
