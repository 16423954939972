<template>
  <section class="login-signup">
    <div class="primary-login">
      <div class="close-icon" @click="closeSlide">
        <img src="../../assets/images/close-icon.svg" alt />
      </div>
      <div class="service-heading">
        <h2>Transaction Details</h2>
      </div>
      <section class="service-popup">
        <div class="service-details">
          <div>
            <p>Customer Name</p>
            <p>{{ selectedWallet?.name }}</p>
          </div>
          <div>
            <p>Email</p>
            <p>{{ selectedWallet?.userId?.email }}</p>
          </div>
          <div>
            <p>Mobile number</p>
            <p>{{ selectedWallet?.mobileNo }}</p>
          </div>
          <div>
            <p>Transaction Type</p>
            <p>{{ selectedWallet?.type }}</p>
          </div>
          <div>
            <p>Amount</p>
            <p>{{ selectedWallet?.amount }}</p>
          </div>
          <div>
            <p>Customer Current Balance</p>
            <p>{{ selectedWallet?.balance }}</p>
          </div>
          <div>
            <p>Transaction Date</p>
            <p>
              {{
                moment(selectedWallet?.createdAt).format(
                  "ddd, D MMMM YYYY, h:mm A"
                )
              }}
            </p>
          </div>
          <div>
            <p>Reason</p>
            <p>{{ selectedWallet?.reason }}</p>
          </div>
          <div>
            <p>Added By</p>
            <p>{{ selectedWallet?.addedBy }}</p>
          </div>

          <br />
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import moment from "moment";
export default {
  name: "WalletDetailModel",
  props: ["selectedWallet"],
  data() {
    return {};
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    closeSlide() {
      this.$parent.$parent.walletModel = false;
    },
  },
};
</script>

<style scoped>
.login-signup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.primary-login {
  width: 500px;
  background: #fff;
  padding: 25px 25px 0 25px;
  border-radius: 20px;
  position: relative;
}
.service-popup {
  height: 400px;
  overflow-y: scroll;
}
.service-popup::-webkit-scrollbar {
  display: none;
}
.primary-login::-webkit-scrollbar {
  display: none;
}
.primary-login {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.close-icon {
  cursor: pointer;
  top: 26px;
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 99;
}
.service-heading h2 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  font-size: 20px;
}
.service-details {
  padding: 25px 0;
}
.service-details div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.service-details div p {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
}
.id-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.id-image div h3 {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-size: 14px;
  opacity: 0.7;
}
.id-image div .placeholder-img {
  width: 250px;
  height: 130px;
  background: #eee;
  margin: 15px 0;
  border-radius: 11px;
}
.add-btn {
  width: 50%;
  margin: 10px;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}

.icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 110px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon img {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  object-fit: cover;
}

.documents {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 90%;
  height: 200px;
  border-radius: 10px;
  background: #f0f2f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.documents img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.docs {
  display: flex;
  flex-direction: column;
}

/* responsive */
@media (max-width: 479px) and (min-width: 320px) {
  .primary-login {
    width: 84%;
  }
}
</style>
