<template>
  <div>
    <div class="header-content">
      <h1>See your Statistics</h1>
    </div>
    <div class="graphs">
      <StatisticsGraph :data="monthlyData" />
      <StatisticsGraph :data="yearlyData" />
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import StatisticsGraph from "./graph/Graph.vue";
export default {
  name: "StatisticsView",
  data() {
    return {
      monthlyData: {
        title: { name: "Weekly Bookings", subTitle: "Calculated last week" },
        data: [
          { name: "Monday", average: "100" },
          { name: "Tuesday", average: "80" },
          { name: "Wednesday", average: "30" },
          { name: "Thursday", average: "60" },
          { name: "Friday", average: "50" },
          { name: "Saturday", average: "60" },
          { name: "Sunday", average: "70" },
        ],
      },
      yearlyData: {
        title: { name: "Yearly Bookings", subTitle: "Calculated last year" },
        data: [
          { name: "Jan", average: "100" },
          { name: "Feb", average: "50" },
          { name: "Mar", average: "40" },
          { name: "Apr", average: "30" },
          { name: "May", average: "80" },
          { name: "Jun", average: "60" },
          { name: "Jul", average: "70" },
          { name: "Aug", average: "100" },
          { name: "Sep", average: "50" },
          { name: "Oct", average: "40" },
          { name: "Nov", average: "30" },
          { name: "Dec", average: "80" },
        ],
      },
    };
  },
  components: {
    StatisticsGraph,
  },
  methods: {
    async getMonthlyBooking() {
      this.$axios
        .get("/admin/monthly-booking")
        .then((res) => {
          let monthlyData = res.data;

          let janBooking = monthlyData?.find((month) => month.month == 1);
          let febBooking = monthlyData?.find((month) => month.month == 2);
          let marBooking = monthlyData?.find((month) => month.month == 3);
          let aprilBooking = monthlyData?.find((month) => month.month == 4);
          let mayBooking = monthlyData?.find((month) => month.month == 5);
          let juneBooking = monthlyData?.find((month) => month.month == 6);
          let julyBooking = monthlyData?.find((month) => month.month == 7);
          let augBooking = monthlyData?.find((month) => month.month == 8);
          let sepBooking = monthlyData?.find((month) => month.month == 9);
          let octBooking = monthlyData?.find((month) => month.month == 10);
          let novBooking = monthlyData?.find((month) => month.month == 11);
          let decBooking = monthlyData?.find((month) => month.month == 12);

          this.yearlyData.data = [
          { name: "Jan", average: janBooking?.percentage || 0 },
          { name: "Feb", average: febBooking?.percentage || 0 },
          { name: "Mar", average: marBooking?.percentage || 0 },
          { name: "Apr", average: aprilBooking?.percentage || 0 },
          { name: "May", average: mayBooking?.percentage || 0 },
          { name: "Jun", average: juneBooking?.percentage || 0 },
          { name: "Jul", average: julyBooking?.percentage || 0 },
          { name: "Aug", average: augBooking?.percentage || 0 },
          { name: "Sep", average: sepBooking?.percentage || 0 },
          { name: "Oct", average: octBooking?.percentage || 0 },
          { name: "Nov", average: novBooking?.percentage || 0 },
          { name: "Dec", average: decBooking?.percentage || 0 },
        ]
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDailyBooking() {
      this.$axios
        .get("/admin/daily-booking")
        .then(() => {
          // console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getMonthlyBooking();
    this.getDailyBooking();
  },
};
</script>

<style scoped>
.graphs {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.header-content h1 {
  text-align: left;
  font-size: 16px;
  margin: 25px 0 20px 0;
}
</style>
