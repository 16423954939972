import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/style.css";
import axios from "axios";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueCookies from 'vue3-cookies';
import { createVuetify } from "vuetify";
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const vuetify = createVuetify({
  components,
  directives,
});

const axiosInstence = () =>
  axios.create({
    baseURL: `${process.env.VUE_APP_BACKEND_URL}api/v1/`,
    withCredentials: false,
  });

const app = createApp(App);
app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDCEDWyZowqvW2Ljtw_RulKYNiPpmB4kAw",
    libraries: "places",
  },
});
app.use(vuetify);
app.use(VueCookies);
app.use(VueSweetalert2);
app.config.globalProperties.$axios = { ...axiosInstence() };
app.use(store);
app.use(router);
app.mount("#app");
