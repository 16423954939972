<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Bookings</h2>
        <p>You can see all the bookings here.</p>
      </div>
      <!-- <div class="add-btn">
        <button @click="CategoryeModelShow">Add New Category</button>
      </div>-->
      <div class="category-main">
        <router-link v-for="(booking, index) in bookingList" :key="index" :to="`/booking/${booking?.name}`" class="category-card">
          <div class="icon">
            <img class="hotel-icon" :src="booking?.image" />
          </div>
          <h6>{{ booking?.name }}</h6>
          <div class="bottom-icons">
            <h3>{{ booking?.totalBookings }}</h3>
          </div>
        </router-link>
        <!-- <router-link to="/booking/appartments" class="category-card">
          <div class="icon">
            <img src="../assets/images/apartment.svg" />
          </div>
          <h6>Appartments</h6>
          <div class="bottom-icons">
            <h3>2447</h3>
          </div>
        </router-link>
        <router-link to="/booking/resorts" class="category-card">
          <div class="icon">
            <img src="../assets/images/resort.svg" />
          </div>
          <h6>Resorts</h6>
          <div class="bottom-icons">
            <h3>2447</h3>
          </div>
        </router-link>
        <router-link to="/booking/farms" class="category-card">
          <div class="icon">
            <img src="../assets/images/forms.svg" />
          </div>
          <h6>Farms</h6>
          <div class="bottom-icons">
            <h3>2447</h3>
          </div>
        </router-link>
        <router-link to="/booking/lounges" class="category-card">
          <div class="icon">
            <img src="../assets/images/lounges.svg" />
          </div>
          <h6>Lounges</h6>
          <div class="bottom-icons">
            <h3>2447</h3>
          </div>
        </router-link>
        <router-link to="/booking/staduims" class="category-card">
          <div class="icon">
            <img src="../assets/images/staduim.svg" />
          </div>
          <h6>Staduims</h6>
          <div class="bottom-icons">
            <h3>2447</h3>
          </div>
        </router-link>
        <router-link to="/booking/chalets" class="category-card">
          <div class="icon">
            <img src="../assets/images/chalets.svg" />
          </div>
          <h6>Chalets</h6>
          <div class="bottom-icons">
            <h3>2447</h3>
          </div>
        </router-link>
        <router-link to="/booking/camps" class="category-card">
          <div class="icon">
            <img src="../assets/images/camps.svg" />
          </div>
          <h6>Camps</h6>
          <div class="bottom-icons">
            <h3>2447</h3>
          </div>
        </router-link>
        <router-link to="/booking/wedding-halls" class="category-card">
          <div class="icon">
            <img src="../assets/images/hall.svg" />
          </div>
          <h6>Wedding Halls</h6>
          <div class="bottom-icons">
            <h3>2447</h3>
          </div>
        </router-link> -->
      </div>
      <CategoriesModel v-if="categoryModel" />
    </section>
  </default-layout>
</template>

<script>
import CategoriesModel from "@/components/Models/ModelCategories.vue";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  name: "BookingView",
  components: {
    DefaultLayout,
    CategoriesModel,
  },
  data() {
    return {
      categoryModel: false,
      bookingList: [],
    };
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    async getRequests() {
      try {
        const bookings = await this.$axios.get("admin/bookings-by-category");
        this.bookingList = bookings.data;
      } catch (error) {
        console.log(error);
      }
    },
    CategoryeModelShow() {
      this.categoryModel = !this.categoryModel;
    },
  },
};
</script>

<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
}
.category-main {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
  padding: 20px 0;
}
.category-card {
  width: 16%;
   margin: 0 0 -12px 0px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.category-card .icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #f0f2f7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-card .icon .hotel-icon {
  width: 45px !important;
}
.category-card .icon img {
  width: 60px;
  height: 50px;
}
.category-card h6 {
  letter-spacing: 0px;
  color: #6a6a6a;
  font-size: 14px;
  text-align: center;
  padding: 15px 0 10px 0;
  font-weight: normal;
}
.bottom-icons {
  display: flex;
  justify-content: space-between;
}
.bottom-icons h3 {
  letter-spacing: 0px;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0;
}
</style>