<template>
  <v-row justify="end">
    <v-dialog
      v-model="dialog"
      fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ props }">
        <div class="view-btns">
          <button v-bind="props"> View All Images </button>
        </div>
      </template>
      <v-card>
        <v-toolbar dark>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn variant="text" @click="dialog = false"> Close </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="image-carousal">
          <v-carousel
            :continuous="false"
            hide-delimiters
            hide-delimiter-background
            delimiter-icon="mdi-square"
            height="300"
          >
            <v-carousel-item v-for="image in images" :src="image" :key="image">
            </v-carousel-item>
          </v-carousel>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: "ImagesBooking",
  props: ["images"],
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    };
  },
};
</script>
<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
.image-carousal {
  height: 100vh;
  display: flex;
  align-items: center;
}
.view-btns {
  width: 100%;
  display: flex;
  justify-content: end;
}
.view-btns button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 20%;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  margin-right: 17px;
}
</style>
