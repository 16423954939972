<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Payment Management</h2>
        <p>You can see booking payment data here.</p>
      </div>
      <div class="search-area">
        <div class="search-main">
          <div class="search-container">
            <select @change="(e) => (searchType = e.target.value)">
              <option value="general">General</option>
              <option value="date">Date</option>
            </select>
            <input
              v-model="search"
              :type="searchType == 'date' ? 'date' : 'text'"
              placeholder="Search here..."
            />
            <img src="../assets/images/search.svg" />
          </div>
        </div>
      </div>
      <!-- <div class="add-btn">
            <button @click="DealModelShow">Add New Deal</button>
          </div> -->
      <div class="payment-container">
        <div class="tab-container">
          <div
            :class="selectedSection == 'booking' ? 'tab selected' : 'tab'"
            @click="() => (this.selectedSection = 'booking')"
          >
            Booking
          </div>
          <div
            :class="
              selectedSection == 'booking_request' ? 'tab selected' : 'tab'
            "
            @click="() => (this.selectedSection = 'booking_request')"
          >
            Booking Request
          </div>
        </div>
        <div
          v-if="this.selectedSection == 'booking'"
          class="payment-detail"
          style="overflow: scroll"
        >
          <table>
            <tr>
              <th>Booking ID</th>
              <th>Date/Time</th>
              <th>Service Name</th>
              <th>Service Address</th>
              <th>CheckIn CheckOut</th>
              <th>Payment Method</th>
              <th>Price(SAR)</th>
              <th>Security Amount</th>
              <th>Action</th>
            </tr>
            <tr
              v-for="(payment, index) in paymentList?.filter(
                (payment) =>
                  payment?.service?.name?.bookingSetting?.bookingType ==
                  'instant'
              )"
              :key="index"
            >
              <td>{{ payment?.bookingId }}</td>
              <td>
                {{
                  moment(payment?.createdAt).format("ddd, D MMMM YYYY, h:mm A")
                }}
              </td>
              <td>{{ payment?.service?.name?.description?.nameInEnglish }}</td>
              <td>
                {{ payment?.service?.name?.address?.address?.slice(0, 10) }}..
              </td>
              <td>
                {{
                  moment(payment?.checkIn).format("ddd, D MMMM YYYY, h:mm A")
                }}
                -
                {{
                  moment(payment?.checkOut).format("ddd, D MMMM YYYY, h:mm A")
                }}
              </td>
              <td>{{ payment?.paymentMethod }}</td>
              <td>{{ payment?.totalPrice }}</td>
              <td>
                {{ payment?.service?.name?.bookingSetting?.securityAmount }}
              </td>

              <td style="display: flex; gap: 5px">
                <div class="view-btn">
                  <button @click="PaymentModelShow(payment)">Details</button>
                </div>
                <div class="view-btn">
                  <button
                    @click="downloadPDF(payment._id)"
                    :disabled="payment.downloadProcessing"
                  >
                    {{ payment.downloadProcessing ? "Loading.." : "Invoice" }}
                  </button>
                </div>
              </td>
            </tr>
          </table>
          <div class="bottom-container">
            <div>
              <p>
                Showing
                {{ pageSelected != 1 ? dataShow * pageSelected - dataShow : 1 }}
                to
                {{
                  paymentList[paymentList.length - 1] &&
                  paymentList[paymentList.length - 1].count
                }}
                of {{ paymentData.length }} entries
              </p>
            </div>
            <div class="payment-pagination">
              <paginate
                v-model="pageSelected"
                :page-range="3"
                :margin-pages="2"
                :page-count="pageCount"
                :click-handler="clickCallback"
                :prev-text="'Previous'"
                :next-text="''"
                :container-class="'pagination'"
                :page-class="'page-item'"
              ></paginate>
            </div>
          </div>
        </div>
        <div
          v-if="this.selectedSection == 'booking_request'"
          class="payment-detail"
          style="overflow: scroll"
        >
          <table>
            <tr>
              <th>Booking ID</th>
              <th>Date/Time</th>
              <th>Service Name</th>
              <th>Service Address</th>
              <th>Request Status</th>
              <th>CheckIn CheckOut</th>
              <th>Payment Method</th>
              <th>Price(SAR)</th>
              <th>Security Amount</th>
              <th>Action</th>
            </tr>
            <tr v-for="(payment, index) in bookingList" :key="index">
              <td>{{ payment?.bookingId }}</td>
              <td>
                {{
                  moment(payment?.createdAt).format("ddd, D MMMM YYYY, h:mm A")
                }}
              </td>
              <td>{{ payment?.service?.name?.description?.nameInEnglish }}</td>
              <td>
                {{ payment?.service?.name?.address?.address?.slice(0, 10) }}..
              </td>
              <td>{{ payment?.status }}</td>
              <td>
                {{
                  moment(payment?.checkIn).format("ddd, D MMMM YYYY, h:mm A")
                }}
                -
                {{
                  moment(payment?.checkOut).format("ddd, D MMMM YYYY, h:mm A")
                }}
              </td>
              <td>{{ payment?.paymentMethod }}</td>
              <td>{{ payment?.totalPrice }}</td>
              <td>
                {{ payment?.service?.name?.bookingSetting?.securityAmount }}
              </td>

              <td style="display: flex; gap: 5px">
                <div class="view-btn">
                  <button @click="PaymentModelShow(payment)">Details</button>
                </div>
                <div class="view-btn">
                  <button
                    @click="downloadPDF(payment._id)"
                    :disabled="payment.downloadProcessing"
                  >
                    {{ payment.downloadProcessing ? "Loading.." : "Invoice" }}
                  </button>
                </div>
              </td>
            </tr>
          </table>
          <div class="bottom-container">
            <div>
              <p>
                Showing
                {{
                  bookingPageSelected != 1
                    ? dataShow * bookingPageSelected - dataShow
                    : 1
                }}
                to
                {{
                  bookingList[bookingList.length - 1] &&
                  bookingList[bookingList.length - 1].count
                }}
                of {{ bookingData.length }} entries
              </p>
            </div>
            <div class="payment-pagination">
              <paginate
                v-model="bookingPageSelected"
                :page-range="3"
                :margin-pages="2"
                :page-count="bookingPageCount"
                :click-handler="bookingClickCallback"
                :prev-text="'Previous'"
                :next-text="''"
                :container-class="'pagination'"
                :page-class="'page-item'"
              ></paginate>
            </div>
          </div>
        </div>
      </div>
      <PaymentDetailModel
        v-if="paymentModel"
        :selectedPayment="selectedPayment"
        @call="close"
      />
    </section>
  </default-layout>
</template>

<script>
import moment from "moment";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import PaymentDetailModel from "@/components/Models/PaymentDetailModel.vue";
import Paginate from "vuejs-paginate-next";

export default {
  name: "PaymentManagement",
  components: {
    DefaultLayout,
    Paginate,
    PaymentDetailModel,
  },
  data() {
    return {
      searchType: "general",
      downloadProcessing: false,
      selectedSection: "booking",
      addBalanceModel: false,
      paymentModel: false,
      paymentData: [],
      paymentList: [],
      allData: [],
      //
      bookingData: [],
      bookingList: [],
      bookingAllData: [],

      //
      bookingPageCount: 0,
      bookingTotal: 0,
      bookingPageSelected: 1,
      //
      pageCount: 0,
      selectedPayment: {},
      total: 0,
      pageSelected: 1,
      //
      dataShow: 10,
      search: "",
    };
  },
  mounted() {
    this.getRequests();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    clickCallback(num) {
      var copyFrom = num * this.dataShow - this.dataShow;
      var copyTo = num * this.dataShow;
      this.paymentList = this.paymentData.slice(copyFrom, copyTo);
    },
    bookingClickCallback(num) {
      var copyFrom = num * this.dataShow - this.dataShow;
      var copyTo = num * this.dataShow;
      this.bookingList = this.bookingData.slice(copyFrom, copyTo);
    },
    addBalanceModelShow() {
      this.addBalanceModel = !this.addBalanceModel;
    },
    PaymentModelShow(paymentObj) {
      this.selectedPayment = paymentObj;
      this.paymentModel = !this.paymentModel;
    },
    reSet() {
      this.pageSelected = 1;
      this.paymentData = [];
      this.paymentList = [];
      this.bookingData = [];
      this.bookingList = [];
    },
    downloadPDF(id) {
      const bookingItem = this.paymentList.find((item) => item._id === id);
      bookingItem.downloadProcessing = true;
      this.$axios
        .get(`admin/invoice/pdfurl/${id}`)
        .then((res) => {
          bookingItem.downloadProcessing = false;
          window.open(res.data?.pdf, "_blank");
          console.log(res.data);
        })
        .catch(() => {
          bookingItem.downloadProcessing = false;
        });
    },
    setBookings(transactions) {
      let allBookingData = [];
      for (let index = 0; index < transactions.data.length; index++) {
        const item = transactions.data[index];
        for (let i = 0; i < item?.bookings.length; i++) {
          const booking = item?.bookings[i];
          allBookingData.push(booking);
        }
      }

      this.total = allBookingData.length;
      //asigning number
      this.paymentData = [];
      for (let index = 0; index < allBookingData.length; index++) {
        const element = allBookingData[index];
        element.count = index + 1;
        this.paymentData.push(element);
      }
      this.pageCount = Math.ceil(this.total / this.dataShow);
      this.allData = [...this.paymentData];
      this.paymentList = this.paymentData.slice(0, this.dataShow);
    },
    setBookingRequest(transactions) {
      debugger;
      let allBookingData = [];
      for (let index = 0; index < transactions.data.length; index++) {
        const item = transactions.data[index];
        for (let i = 0; i < item?.bookings.length; i++) {
          const booking = item?.bookings[i];
          allBookingData.push(booking);
        }
      }
      allBookingData = allBookingData.filter(
        (payment) =>
          payment?.service?.name?.bookingSetting?.bookingType != "instant"
      );

      this.bookingTotal = allBookingData.length;
      //asigning number
      this.bookingData = [];
      for (let index = 0; index < allBookingData.length; index++) {
        const element = allBookingData[index];
        element.count = index + 1;
        this.bookingData.push(element);
      }
      this.bookingPageCount = Math.ceil(this.bookingTotal / this.dataShow);
      this.bookingAllData = [...this.bookingData];
      this.bookingList = this.bookingData.slice(0, this.dataShow);
    },
    async getRequests() {
      this.reSet();
      try {
        const transactions = await this.$axios.get(
          "admin/bookings-by-category"
        );
        this.setBookings(transactions);
        this.setBookingRequest(transactions);
      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.paymentModel = false;
      this.addBalanceModel = false;
      this.getRequests();
    },
  },
  watch: {
    search: function (newVal) {
      (() => {
        const allData = JSON.parse(JSON.stringify(this.allData));
        let filterResults = allData.filter((i) => {
          let result = false;
          if (i?.user?.email) {
            result =
              result ||
              i?.user?.email
                .toLowerCase()
                .match(new RegExp(newVal.toLowerCase()));
          }
          if (i?.vender?.email) {
            result =
              result ||
              i?.vender?.email
                .toLowerCase()
                .match(new RegExp(newVal.toLowerCase()));
          }
          if (i?.vender?.phone) {
            result =
              result || String(i?.vender?.phone).match(new RegExp(newVal));
          }
          if (i?.user?.phone) {
            result = result || String(i?.user?.phone).match(new RegExp(newVal));
          }
          if (this.searchType == "date" && i?.checkIn) {
            result =
              result ||
              i?.checkIn.toLowerCase().match(new RegExp(newVal.toLowerCase()));
          }
          if (this.searchType == "date" && i?.checkOut) {
            result =
              result ||
              i?.checkOut.toLowerCase().match(new RegExp(newVal.toLowerCase()));
          }
          if (this.searchType == "date" && i?.createdAt) {
            result =
              result ||
              i?.createdAt
                .toLowerCase()
                .match(new RegExp(newVal.toLowerCase()));
          }
          return result;
        });

        if (newVal === "") {
          filterResults = allData;
        }
        this.paymentdata = filterResults;

        this.total = filterResults.length;
        for (let index = 0; index < filterResults.length; index++) {
          const element = filterResults[index];
          element.count = index + 1;
        }
        this.pageCount = Math.ceil(this.total / this.dataShow);
        this.paymentList = filterResults.slice(0, this.dataShow);
      })();
      (() => {
        const allData = JSON.parse(JSON.stringify(this.bookingAllData));
        let filterResults = allData.filter((i) => {
          let result = false;
          if (i?.user?.email) {
            result =
              result ||
              i?.user?.email
                .toLowerCase()
                .match(new RegExp(newVal.toLowerCase()));
          }
          if (i?.vender?.email) {
            result =
              result ||
              i?.vender?.email
                .toLowerCase()
                .match(new RegExp(newVal.toLowerCase()));
          }
          if (i?.vender?.phone) {
            result =
              result || String(i?.vender?.phone).match(new RegExp(newVal));
          }
          if (i?.user?.phone) {
            result = result || String(i?.user?.phone).match(new RegExp(newVal));
          }
          if (this.searchType == "date" && i?.checkIn) {
            result =
              result ||
              i?.checkIn.toLowerCase().match(new RegExp(newVal.toLowerCase()));
          }
          if (this.searchType == "date" && i?.checkOut) {
            result =
              result ||
              i?.checkOut.toLowerCase().match(new RegExp(newVal.toLowerCase()));
          }
          if (this.searchType == "date" && i?.createdAt) {
            result =
              result ||
              i?.createdAt
                .toLowerCase()
                .match(new RegExp(newVal.toLowerCase()));
          }
          return result;
        });

        if (newVal === "") {
          filterResults = allData;
        }
        this.bookingData = filterResults;

        this.bookingTotal = filterResults.length;
        for (let index = 0; index < filterResults.length; index++) {
          const element = filterResults[index];
          element.count = index + 1;
        }
        this.bookingPageCount = Math.ceil(this.bookingTotal / this.dataShow);
        this.bookingList = filterResults.slice(0, this.dataShow);
      })();
    },
  },
};
</script>
<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 100%;
}
.payment-detail {
  padding: 20px 0 40px 0;
}
.payment-detail table {
  border-collapse: collapse;
  width: 100%;
  box-shadow: 0px 0px 39px #00000012;
  border-radius: 11px;
  opacity: 1;
  background: #fff;
}

.payment-detail table th {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding: 15px 5px;
  white-space: nowrap;
}
.payment-detail table td {
  padding: 15px 5px;
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  font-weight: normal;
  color: #9a9a9a;
  white-space: nowrap;
  font-size: 12px;
}
.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.view-btn button {
  border: none;
  outline: none;
  border-radius: 7px;
  opacity: 1;
  background: #febb12;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 10px;
  cursor: pointer;
}
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-container div p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 12px;
}

.search-area {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.search-container {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 0 20px;
  margin-right: 10px;
  height: 46px;
}

.search-main {
  display: flex;
}
.search-container img {
  width: 15px;
}
.search-container input {
  outline: none;
  border: none;
  /* height: 38px; */
  color: #828282;
  width: 230px;
}

.search-container select {
  outline: none;
  border: none;
  /* height: 38px; */
  color: #828282;
}

.tab-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}

.tab-container .tab {
  padding: 20px 0px;
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
}
.selected {
  color: #fff !important;
  background-color: #febb12 !important;
}
</style>
