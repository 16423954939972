<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="user-profile">
        <Profile />
      </div>
      <div class="top-heading">
        <h2>User - {{ user }}</h2>
      </div>
    </section>
    <!-- app users -->
    <section class="main-wrapper" style="margin-top: 15px">
      <div class="add-btn">
        <div>
          <h2>Permissions</h2>
        </div>
      </div>
      <div class="service-container">
        <div class="service-detail">
          <table>
            <tr>
              <th></th>
              <th>
                <div class="form-group">
                  <label for="cb">View</label>
                </div>
              </th>
              <th>
                <div class="form-group">
                  <label for="cb">Create</label>
                </div>
              </th>
              <th>
                <div class="form-group">
                  <label for="cb">Edit</label>
                </div>
              </th>
              <th>
                <div class="form-group">
                  <label for="cb">Delete</label>
                </div>
              </th>
            </tr>
            <!-- <tr v-for="(deal, index) in serviceList" :key="index"> -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_dashboard(e)"
                  />
                  Dashboard
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="dashboard_view"
                    @change="() => (dashboard_view = !dashboard_view)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_category(e)"
                  />
                  Categories
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="category_view"
                    @change="() => (category_view = !category_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="category_create"
                    @change="() => (category_create = !category_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="category_edit"
                    @change="() => (category_edit = !category_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="category_delete"
                    @change="() => (category_delete = !category_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_deal(e)"
                  />
                  Deals
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="deal_view"
                    @change="() => (deal_view = !deal_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="deal_create"
                    @change="() => (deal_create = !deal_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="deal_edit"
                    @change="() => (deal_edit = !deal_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="deal_delete"
                    @change="() => (deal_delete = !deal_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_vendor(e)"
                  />
                  Vendors
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="vendor_view"
                    @change="() => (vendor_view = !vendor_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="vendor_create"
                    @change="() => (vendor_create = !vendor_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="vendor_edit"
                    @change="() => (vendor_edit = !vendor_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="vendor_delete"
                    @change="() => (vendor_delete = !vendor_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_booking(e)"
                  />
                  Bookings
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="booking_view"
                    @change="() => (booking_view = !booking_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="booking_create"
                    @change="() => (booking_create = !booking_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="booking_edit"
                    @change="() => (booking_edit = !booking_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="booking_delete"
                    @change="() => (booking_delete = !booking_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_cancelation_request(e)"
                  />
                  Cancelation Requests
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="cancelation_request_view"
                    @change="
                      () =>
                        (cancelation_request_view = !cancelation_request_view)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="cancelation_request_create"
                    @change="
                      () =>
                        (cancelation_request_create =
                          !cancelation_request_create)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="cancelation_request_edit"
                    @change="
                      () =>
                        (cancelation_request_edit = !cancelation_request_edit)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="cancelation_request_delete"
                    @change="
                      () =>
                        (cancelation_request_delete =
                          !cancelation_request_delete)
                    "
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_user(e)"
                  />
                  Users
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="user_view"
                    @change="() => (user_view = !user_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="user_create"
                    @change="() => (user_create = !user_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="user_edit"
                    @change="() => (user_edit = !user_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="user_delete"
                    @change="() => (user_delete = !user_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_service(e)"
                  />
                  Services
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="service_view"
                    @change="() => (service_view = !service_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="service_create"
                    @change="() => (service_create = !service_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="service_edit"
                    @change="() => (service_edit = !service_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="service_delete"
                    @change="() => (service_delete = !service_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_city(e)"
                  />
                  Cities
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="city_view"
                    @change="() => (city_view = !city_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="city_create"
                    @change="() => (city_create = !city_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="city_edit"
                    @change="() => (city_edit = !city_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="city_delete"
                    @change="() => (city_delete = !city_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_balance(e)"
                  />
                  Balance
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="balance_view"
                    @change="() => (balance_view = !balance_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="balance_create"
                    @change="() => (balance_create = !balance_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="balance_edit"
                    @change="() => (balance_edit = !balance_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="balance_delete"
                    @change="() => (balance_delete = !balance_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_setting(e)"
                  />
                  Settings
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="setting_view"
                    @change="() => (setting_view = !setting_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="setting_create"
                    @change="() => (setting_create = !setting_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="setting_edit"
                    @change="() => (setting_edit = !setting_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="setting_delete"
                    @change="() => (setting_delete = !setting_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_wallet_management(e)"
                  />
                  Wallet Management
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="wallet_management_view"
                    @change="
                      () => (wallet_management_view = !wallet_management_view)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="wallet_management_create"
                    @change="
                      () =>
                        (wallet_management_create = !wallet_management_create)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="wallet_management_edit"
                    @change="
                      () => (wallet_management_edit = !wallet_management_edit)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="wallet_management_delete"
                    @change="
                      () =>
                        (wallet_management_delete = !wallet_management_delete)
                    "
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_payment_management(e)"
                  />
                  Payment Management
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="payment_management_view"
                    @change="
                      () => (payment_management_view = !payment_management_view)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="payment_management_create"
                    @change="
                      () =>
                        (payment_management_create = !payment_management_create)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="payment_management_edit"
                    @change="
                      () => (payment_management_edit = !payment_management_edit)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="payment_management_delete"
                    @change="
                      () =>
                        (payment_management_delete = !payment_management_delete)
                    "
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_homepage_management(e)"
                  />
                  HomePage Management
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="homepage_management_view"
                    @change="
                      () =>
                        (homepage_management_view = !homepage_management_view)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="homepage_management_create"
                    @change="
                      () =>
                        (homepage_management_create =
                          !homepage_management_create)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="homepage_management_edit"
                    @change="
                      () =>
                        (homepage_management_edit = !homepage_management_edit)
                    "
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="homepage_management_delete"
                    @change="
                      () =>
                        (homepage_management_delete =
                          !homepage_management_delete)
                    "
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_reports(e)"
                  />
                  Reports
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="reports_view"
                    @change="() => (reports_view = !reports_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="reports_create"
                    @change="() => (reports_create = !reports_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="reports_edit"
                    @change="() => (reports_edit = !reports_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="reports_delete"
                    @change="() => (reports_delete = !reports_delete)"
                  />
                </div>
              </td>
            </tr>
            <!-- block -->
            <tr>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    @change="(e) => control_frontend(e)"
                  />
                  Frontend
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="frontend_view"
                    @change="() => (frontend_view = !frontend_view)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="frontend_create"
                    @change="() => (frontend_create = !frontend_create)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="frontend_edit"
                    @change="() => (frontend_edit = !frontend_edit)"
                  />
                </div>
              </td>
              <td>
                <div class="form-group">
                  <input
                    id="cb"
                    type="checkbox"
                    class="checkboxField"
                    :checked="frontend_delete"
                    @change="() => (frontend_delete = !frontend_delete)"
                  />
                </div>
              </td>
            </tr>
          </table>
          <div class="add-user">
            <button @click="handleSave()">Save Permissions</button>
          </div>
        </div>
      </div>
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import Profile from "@/components/common/Profile.vue";

export default {
  name: "PermissionsComponent",
  components: {
    DefaultLayout,
    Profile,
  },
  data: () => {
    return {
      user: "",
      id: "",
      dashboard_view: false,
      category_view: false,
      category_create: false,
      category_edit: false,
      category_delete: false,
      deal_view: false,
      deal_create: false,
      deal_edit: false,
      deal_delete: false,
      vendor_view: false,
      vendor_create: false,
      vendor_edit: false,
      vendor_delete: false,
      booking_view: false,
      booking_create: false,
      booking_edit: false,
      booking_delete: false,
      cancelation_request_view: false,
      cancelation_request_create: false,
      cancelation_request_edit: false,
      cancelation_request_delete: false,
      user_view: false,
      user_create: false,
      user_edit: false,
      user_delete: false,
      service_view: false,
      service_create: false,
      service_edit: false,
      service_delete: false,
      city_view: false,
      city_create: false,
      city_edit: false,
      city_delete: false,
      balance_view: false,
      balance_create: false,
      balance_edit: false,
      balance_delete: false,
      setting_view: false,
      setting_create: false,
      setting_edit: false,
      setting_delete: false,
      wallet_management_view: false,
      wallet_management_create: false,
      wallet_management_edit: false,
      wallet_management_delete: false,
      payment_management_view: false,
      payment_management_create: false,
      payment_management_edit: false,
      payment_management_delete: false,
      homepage_management_view: false,
      homepage_management_create: false,
      homepage_management_edit: false,
      homepage_management_delete: false,
      reports_view: false,
      reports_create: false,
      reports_edit: false,
      reports_delete: false,
      frontend_view: false,
      frontend_create: false,
      frontend_edit: false,
      frontend_delete: false,
    };
  },
  methods: {
    async getUser(userId) {
      let { data } = await this.$axios.get(`admin/users/${userId}`);
      this.user = `${data?.firstName ? data?.firstName : ""} ${
        data?.lastName ? data?.lastName : ""
      }`;
      this.id = data._id;
      console.log(data);
      // set permission values
      let permissions = data.resources;

      let dashboard_permissions = permissions.find(
        (permission) => permission.name == "dashboard"
      );
      let category_permissions = permissions.find(
        (permission) => permission.name == "category"
      );
      let deal_permissions = permissions.find(
        (permission) => permission.name == "deal"
      );
      let vendor_permissions = permissions.find(
        (permission) => permission.name == "vendor"
      );
      let booking_permissions = permissions.find(
        (permission) => permission.name == "booking"
      );
      let cancelation_request_permissions = permissions.find(
        (permission) => permission.name == "cancelation_request"
      );
      let user_permissions = permissions.find(
        (permission) => permission.name == "user"
      );
      let service_permissions = permissions.find(
        (permission) => permission.name == "service"
      );
      let city_permissions = permissions.find(
        (permission) => permission.name == "city"
      );
      let balance_permissions = permissions.find(
        (permission) => permission.name == "balance"
      );
      let setting_permissions = permissions.find(
        (permission) => permission.name == "setting"
      );
      let wallet_management_permissions = permissions.find(
        (permission) => permission.name == "wallet_management"
      );
      let payment_management_permissions = permissions.find(
        (permission) => permission.name == "payment_management"
      );
      let homepage_management_permissions = permissions.find(
        (permission) => permission.name == "homepage_management"
      );
      let reports_permissions = permissions.find(
        (permission) => permission.name == "reports"
      );
      let frontend_permissions = permissions.find(
        (permission) => permission.name == "frontend"
      );

      // modify the values
      this.dashboard_view = dashboard_permissions.permissions.view;
      this.category_view = category_permissions.permissions.view;
      this.category_create = category_permissions.permissions.create;
      this.category_edit = category_permissions.permissions.edit;
      this.category_delete = category_permissions.permissions.delete;
      this.deal_view = deal_permissions.permissions.view;
      this.deal_create = deal_permissions.permissions.create;
      this.deal_edit = deal_permissions.permissions.edit;
      this.deal_delete = deal_permissions.permissions.delete;
      this.vendor_view = vendor_permissions.permissions.view;
      this.vendor_create = vendor_permissions.permissions.create;
      this.vendor_edit = vendor_permissions.permissions.edit;
      this.vendor_delete = vendor_permissions.permissions.delete;
      this.booking_view = booking_permissions.permissions.view;
      this.booking_create = booking_permissions.permissions.create;
      this.booking_edit = booking_permissions.permissions.edit;
      this.booking_delete = booking_permissions.permissions.delete;
      this.cancelation_request_view =
        cancelation_request_permissions.permissions.view;
      this.cancelation_request_create =
        cancelation_request_permissions.permissions.create;
      this.cancelation_request_edit =
        cancelation_request_permissions.permissions.edit;
      this.cancelation_request_delete =
        cancelation_request_permissions.permissions.delete;
      this.user_view = user_permissions.permissions.view;
      this.user_create = user_permissions.permissions.create;
      this.user_edit = user_permissions.permissions.edit;
      this.user_delete = user_permissions.permissions.delete;
      this.service_view = service_permissions.permissions.view;
      this.service_create = service_permissions.permissions.create;
      this.service_edit = service_permissions.permissions.edit;
      this.service_delete = service_permissions.permissions.delete;
      this.city_view = city_permissions.permissions.view;
      this.city_create = city_permissions.permissions.create;
      this.city_edit = city_permissions.permissions.edit;
      this.city_delete = city_permissions.permissions.delete;
      this.balance_view = balance_permissions.permissions.view;
      this.balance_create = balance_permissions.permissions.create;
      this.balance_edit = balance_permissions.permissions.edit;
      this.balance_delete = balance_permissions.permissions.delete;
      this.setting_view = setting_permissions.permissions.view;
      this.setting_create = setting_permissions.permissions.create;
      this.setting_edit = setting_permissions.permissions.edit;
      this.setting_delete = setting_permissions.permissions.delete;
      this.wallet_management_view =
        wallet_management_permissions.permissions.view;
      this.wallet_management_create =
        wallet_management_permissions.permissions.create;
      this.wallet_management_edit =
        wallet_management_permissions.permissions.edit;
      this.wallet_management_delete =
        wallet_management_permissions.permissions.delete;
      this.payment_management_view =
        payment_management_permissions.permissions.view;
      this.payment_management_create =
        payment_management_permissions.permissions.create;
      this.payment_management_edit =
        payment_management_permissions.permissions.edit;
      this.payment_management_delete =
        payment_management_permissions.permissions.delete;
      this.homepage_management_view =
        homepage_management_permissions.permissions.view;
      this.homepage_management_create =
        homepage_management_permissions.permissions.create;
      this.homepage_management_edit =
        homepage_management_permissions.permissions.edit;
      this.homepage_management_delete =
        homepage_management_permissions.permissions.delete;
      this.reports_view = reports_permissions.permissions.view;
      this.reports_create = reports_permissions.permissions.create;
      this.reports_edit = reports_permissions.permissions.edit;
      this.reports_delete = reports_permissions.permissions.delete;
      this.frontend_view = frontend_permissions.permissions.view;
      this.frontend_create = frontend_permissions.permissions.create;
      this.frontend_edit = frontend_permissions.permissions.edit;
      this.frontend_delete = frontend_permissions.permissions.delete;
    },
    debug() {
      console.log(this.category_view);
    },
    async handleSave() {
      try {
        var res = this.$axios.post("/admin/add-permissions", {
          id: this.id,
          resources: [
            {
              name: "dashboard",
              permissions: {
                view: this.dashboard_view,
              },
            },
            {
              name: "category",
              permissions: {
                view: this.category_view,
                create: this.category_create,
                edit: this.category_edit,
                delete: this.category_delete,
              },
            },
            {
              name: "deal",
              permissions: {
                view: this.deal_view,
                create: this.deal_create,
                edit: this.deal_edit,
                delete: this.deal_delete,
              },
            },
            {
              name: "vendor",
              permissions: {
                view: this.vendor_view,
                create: this.vendor_create,
                edit: this.vendor_edit,
                delete: this.vendor_delete,
              },
            },
            {
              name: "booking",
              permissions: {
                view: this.booking_view,
                create: this.booking_create,
                edit: this.booking_edit,
                delete: this.booking_delete,
              },
            },
            {
              name: "cancelation_request",
              permissions: {
                view: this.cancelation_request_view,
                create: this.cancelation_request_create,
                edit: this.cancelation_request_edit,
                delete: this.cancelation_request_delete,
              },
            },
            {
              name: "user",
              permissions: {
                view: this.user_view,
                create: this.user_create,
                edit: this.user_edit,
                delete: this.user_delete,
              },
            },
            {
              name: "service",
              permissions: {
                view: this.service_view,
                create: this.service_create,
                edit: this.service_edit,
                delete: this.service_delete,
              },
            },
            {
              name: "city",
              permissions: {
                view: this.city_view,
                create: this.city_create,
                edit: this.city_edit,
                delete: this.city_delete,
              },
            },
            {
              name: "balance",
              permissions: {
                view: this.balance_view,
                create: this.balance_create,
                edit: this.balance_edit,
                delete: this.balance_delete,
              },
            },
            {
              name: "setting",
              permissions: {
                view: this.setting_view,
                create: this.setting_create,
                edit: this.setting_edit,
                delete: this.setting_delete,
              },
            },
            {
              name: "wallet_management",
              permissions: {
                view: this.wallet_management_view,
                create: this.wallet_management_create,
                edit: this.wallet_management_edit,
                delete: this.wallet_management_delete,
              },
            },
            {
              name: "payment_management",
              permissions: {
                view: this.payment_management_view,
                create: this.payment_management_create,
                edit: this.payment_management_edit,
                delete: this.payment_management_delete,
              },
            },
            {
              name: "homepage_management",
              permissions: {
                view: this.homepage_management_view,
                create: this.homepage_management_create,
                edit: this.homepage_management_edit,
                delete: this.homepage_management_delete,
              },
            },
            {
              name: "reports",
              permissions: {
                view: this.reports_view,
                create: this.reports_create,
                edit: this.reports_edit,
                delete: this.reports_delete,
              },
            },
            {
              name: "frontend",
              permissions: {
                view: this.frontend_view,
                create: this.frontend_create,
                edit: this.frontend_edit,
                delete: this.frontend_delete,
              },
            },
          ],
        });
        if (res) {
          this.$swal({
            icon: "success",
            title: "Permissions Updated!",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    control_dashboard(e) {
      if (e.target.checked) {
        this.dashboard_view = true;
      } else {
        this.dashboard_view = false;
      }
    },
    control_category(e) {
      if (e.target.checked) {
        this.category_view = true;
        this.category_create = true;
        this.category_edit = true;
        this.category_delete = true;
      } else {
        this.category_view = false;
        this.category_create = false;
        this.category_edit = false;
        this.category_delete = false;
      }
    },
    control_deal(e) {
      if (e.target.checked) {
        this.deal_view = true;
        this.deal_create = true;
        this.deal_edit = true;
        this.deal_delete = true;
      } else {
        this.deal_view = false;
        this.deal_create = false;
        this.deal_edit = false;
        this.deal_delete = false;
      }
    },
    control_vendor(e) {
      if (e.target.checked) {
        this.vendor_view = true;
        this.vendor_create = true;
        this.vendor_edit = true;
        this.vendor_delete = true;
      } else {
        this.vendor_view = false;
        this.vendor_create = false;
        this.vendor_edit = false;
        this.vendor_delete = false;
      }
    },
    control_booking(e) {
      if (e.target.checked) {
        this.booking_view = true;
        this.booking_create = true;
        this.booking_edit = true;
        this.booking_delete = true;
      } else {
        this.booking_view = false;
        this.booking_create = false;
        this.booking_edit = false;
        this.booking_delete = false;
      }
    },
    control_cancelation_request(e) {
      if (e.target.checked) {
        this.cancelation_request_view = true;
        this.cancelation_request_create = true;
        this.cancelation_request_edit = true;
        this.cancelation_request_delete = true;
      } else {
        this.cancelation_request_view = false;
        this.cancelation_request_create = false;
        this.cancelation_request_edit = false;
        this.cancelation_request_delete = false;
      }
    },
    control_user(e) {
      if (e.target.checked) {
        this.user_view = true;
        this.user_create = true;
        this.user_edit = true;
        this.user_delete = true;
      } else {
        this.user_view = false;
        this.user_create = false;
        this.user_edit = false;
        this.user_delete = false;
      }
    },
    control_service(e) {
      if (e.target.checked) {
        this.service_view = true;
        this.service_create = true;
        this.service_edit = true;
        this.service_delete = true;
      } else {
        this.service_view = false;
        this.service_create = false;
        this.service_edit = false;
        this.service_delete = false;
      }
    },
    control_city(e) {
      if (e.target.checked) {
        this.city_view = true;
        this.city_create = true;
        this.city_edit = true;
        this.city_delete = true;
      } else {
        this.city_view = false;
        this.city_create = false;
        this.city_edit = false;
        this.city_delete = false;
      }
    },
    control_balance(e) {
      if (e.target.checked) {
        this.balance_view = true;
        this.balance_create = true;
        this.balance_edit = true;
        this.balance_delete = true;
      } else {
        this.balance_view = false;
        this.balance_create = false;
        this.balance_edit = false;
        this.balance_delete = false;
      }
    },
    control_setting(e) {
      if (e.target.checked) {
        this.setting_view = true;
        this.setting_create = true;
        this.setting_edit = true;
        this.setting_delete = true;
      } else {
        this.setting_view = false;
        this.setting_create = false;
        this.setting_edit = false;
        this.setting_delete = false;
      }
    },
    control_wallet_management(e) {
      if (e.target.checked) {
        this.wallet_management_view = true;
        this.wallet_management_create = true;
        this.wallet_management_edit = true;
        this.wallet_management_delete = true;
      } else {
        this.wallet_management_view = false;
        this.wallet_management_create = false;
        this.wallet_management_edit = false;
        this.wallet_management_delete = false;
      }
    },
    control_payment_management(e) {
      if (e.target.checked) {
        this.payment_management_view = true;
        this.payment_management_create = true;
        this.payment_management_edit = true;
        this.payment_management_delete = true;
      } else {
        this.payment_management_view = false;
        this.payment_management_create = false;
        this.payment_management_edit = false;
        this.payment_management_delete = false;
      }
    },
    control_homepage_management(e) {
      if (e.target.checked) {
        this.homepage_management_view = true;
        this.homepage_management_create = true;
        this.homepage_management_edit = true;
        this.homepage_management_delete = true;
      } else {
        this.homepage_management_view = false;
        this.homepage_management_create = false;
        this.homepage_management_edit = false;
        this.homepage_management_delete = false;
      }
    },
    control_reports(e) {
      if (e.target.checked) {
        this.reports_view = true;
        this.reports_create = true;
        this.reports_edit = true;
        this.reports_delete = true;
      } else {
        this.reports_view = false;
        this.reports_create = false;
        this.reports_edit = false;
        this.reports_delete = false;
      }
    },
    control_frontend(e) {
      if (e.target.checked) {
        this.frontend_view = true;
        this.frontend_create = true;
        this.frontend_edit = true;
        this.frontend_delete = true;
      } else {
        this.frontend_view = false;
        this.frontend_create = false;
        this.frontend_edit = false;
        this.frontend_delete = false;
      }
    },
  },
  mounted() {},
  watch: {
    "$route.params": {
      immediate: true,
      handler({ userId }) {
        this.getUser(userId);
      },
    },
  },
};
</script>
<style scoped>
.service-container .inputs {
  display: flex;
  flex-wrap: wrap;
}
.service-container .inputs input {
  outline: none;
  font-size: 12px;
  padding: 15px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 25%;
  margin: 20px 12px 20px 0px;
}
.user-profile {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}
.icon-deals img {
  width: 40px;
  cursor: pointer;
}
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.add-btn a {
  text-decoration: none;
  color: #fff;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 11px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  min-width: 148px;
}
.service-detail {
  padding: 20px 0 40px 0;
}
.service-detail table {
  border-collapse: collapse;
  width: 100%;
  /* box-shadow: 0px 0px 39px #00000012;
  border-radius: 11px; */
  opacity: 1;
  /* background: #fff; */
}

.service-detail table th {
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 14px;
  font-weight: 600;
  /* border-bottom: 1px solid #eee; */
  padding: 15px;
}
.service-detail table td {
  padding: 15px;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-weight: normal;
  color: #9a9a9a;
  /* opacity: 0.7; */
  font-size: 12px;
}
.profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.view-btn button {
  border: none;
  outline: none;
  border-radius: 7px;
  opacity: 1;
  background: #febb12;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 50px;
  cursor: pointer;
}
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-container div p {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.7;
  font-size: 12px;
}
.add-btn {
  justify-content: space-between;
  align-items: center;
}
.add-btn h2 {
  font-size: 14px;
}
.search-container {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 0 20px;
  margin-right: 10px;
}
.search-main {
  display: flex;
}
.search-container img {
  width: 15px;
}
.search-container input {
  outline: none;
  border: none;
  /* height: 38px; */
  color: #828282;
  width: 230px;
}
.select-type {
  color: #828282;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 10px;
}
select {
  outline: none;
  border: none;
  color: #828282;
  padding: 0 5px;
  font-size: 12px;
}

.permissions-set {
  margin-top: 14px;
}
.add-user {
  text-align: center;
}
.add-user button {
  margin-top: 30px;
  border: none;
  outline: none;
  border-radius: 30px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
}
</style>
