<template>
  <section class="login-signup">
    <div class="primary-login">
      <div class="close-icon" @click="closeSlide">
        <img src="../../../assets/images/close-icon.svg" alt />
      </div>
      <div class="service-heading">
        <h2 v-if="mode === 'create'">Add New User</h2>
        <h2 v-if="mode === 'edit'">Edit User</h2>
      </div>
      <Form @submit="addNewUser" :validation-schema="schema">
        <div class="category-content">
          <div class="inputs">
            <div class="input-wrapper">
              <Field
                name="firstName"
                type="text"
                placeholder="First name"
                @input="firstNameInput"
                v-model="addUser.firstName"
                required
              />
              <ErrorMessage class="error-msg" name="firstName" />
            </div>
            <div class="input-wrapper">
              <Field
                name="lastName"
                type="text"
                @input="lastNameInput"
                placeholder="Last name"
                v-model="addUser.lastName"
                required
              />
              <ErrorMessage class="error-msg" name="lastName" />
            </div>
            <div class="input-wrapper">
              <Field
                name="email"
                type="email"
                placeholder="Email"
                :readonly="mode === 'edit'"
                v-model="addUser.email"
                required
              />
              <ErrorMessage class="error-msg" name="email" />
            </div>
            <div class="input-wrapper">
              <Field
                name="phone"
                type="text"
                placeholder="Phone"
                v-model="addUser.phone"
                required
              />
              <ErrorMessage class="error-msg" name="phone" />
            </div>
            <div
              v-if="mode === 'create' && role === 'admin'"
              class="input-wrapper"
            >
              <div class="pass-field">
                <Field
                  name="password"
                  :type="showPass ? 'text' : 'password'"
                  placeholder="Password"
                  v-model="addUser.password"
                  required
                />
                <img
                  v-if="!showPass"
                  src="../../../assets/images/eye-pass-open.svg"
                  @click="showPass = !showPass"
                />
                <img
                  v-if="showPass"
                  src="../../../assets/images/eye-pass-close.svg"
                  @click="showPass = !showPass"
                />
              </div>

              <ErrorMessage class="error-msg" name="password" />
            </div>
          </div>
          <div class="image-upload-box">
            <img v-if="addUser.image" :src="addUser.image" />
            <div class="upload-file">
              <p>Add user image</p>
              <label for="inputTag" :class="{ active: errors.file }">
                Add image
                <input
                  id="inputTag"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  @change="handleFile"
                />
              </label>
            </div>
          </div>

          <div class="add-btn">
            <button type="submit" :disabled="loading">
              {{ !loading ? "Submit" : "Loading..." }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "CreateUserModel",
  props: ["user", "mode", "role"],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    let addUser = {};
    if (this.user) {
      addUser = this.user;
    }
    return {
      errors: {
        firstName: false,
        lastName: false,
      },
      addUser: addUser,
      file: null,
      showPass: false,
      //
      loading: false,
    };
  },
  computed: {
    schema() {
      const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
      return yup.object({
        firstName: yup.string().trim().required().min(3).label("First Name"),
        lastName: yup.string().trim().required().min(3).label("Last Name"),
        email: yup.string().trim().required().email().label("Email"),
        phone: yup
          .string()
          .required()
          .matches(phoneRegExp, "Phone number is not valid"),
        password: yup
          .string()
          .trim()
          .when([], {
            is: () => this.mode === "create" && this.role === "admin",
            then: () => yup.string().required().min(3).label("Password"),
          }),
      });
    },
  },
  mounted() {
    if (this.mode === "create") {
      this.addUser = {};
    }
  },
  methods: {
    firstNameInput() {
      this.errors.firstName = false;
    },
    lastNameInput() {
      this.errors.lastName = false;
    },
    handleFile(event) {
      if (event.target.files && event.target.files[0]) {
        this.addUser.image = URL.createObjectURL(event.target.files[0]);
        let formData = new FormData();
        formData.append("image", event.target.files[0]);

        this.errors.file = false;
        this.file = formData;
      }
    },
    async addNewUser() {
      if (!this.addUser.firstName || !this.addUser.lastName) {
        this.errors.firstName = true;
        this.errors.lastName = true;
        return;
      }
      this.addUser.firstName = this.addUser.firstName.trim();
      this.addUser.lastName = this.addUser.lastName.trim();
      if (!this.addUser.firstName) {
        this.errors.firstName = true;
        return;
      }
      if (!this.addUser.lastName) {
        this.errors.lastName = true;
        return;
      }
      this.loading = true;
      var imageUrl = "";
      if (this.file) {
        imageUrl = await this.uploadFiles();
      }
      this.add(imageUrl);
    },
    async add(imageUrl) {
      var payload = this.addUser;
      payload.image = imageUrl;
      try {
        var res;
        var title;
        if (this.mode === "create") {
          if (this.role === "admin") {
            res = await this.$axios.post(`/admin/admin-user`, payload);
            title = "Admin created & Password has been sent to email";
          } else {
            res = await this.$axios.post(`/admin/app-user`, payload);
            title = "User created successfully";
          }
        } else {
          res = await this.$axios.put(
            `/admin/update/${this.user._id}`,
            payload
          );
          title = "User details updated successfully";
        }
        if (res) {
          this.loading = false;
          this.$swal({
            icon: "success",
            title,
            showConfirmButton: false,
            timer: 3000,
          });
          setTimeout(() => {
            this.$emit("reCall", res.data);
            this.closeSlide();
          }, 3000);
        }
      } catch (error) {
        this.loading = false;
        const msg =
          error.response.data.error || error.message || "Something went wrong";
        this.$swal({
          icon: "error",
          title: msg,
          showConfirmButton: false,
          timer: 3000,
        });
        console.log(error);
      }
    },
    async uploadFiles() {
      try {
        const imagesData = await this.$axios.post("user/upload", this.file, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return imagesData.data.url;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    closeSlide() {
      this.$emit("close");
    },
  },
  watch: {
    mode: function (newVal) {
      console.log(newVal);
      // watch it
      if (newVal) {
        this.addUser = {};
      }
    },
  },
};
</script>

<style scoped>
.login-signup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.primary-login {
  width: 700px;
  background: #fff;
  padding: 25px;
  border-radius: 20px;
  position: relative;
  overflow: scroll;
}
.primary-login::-webkit-scrollbar {
  display: none;
}
.primary-login {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.close-icon {
  cursor: pointer;
  top: 26px;
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 99;
}
.service-heading h2 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  font-size: 18px;
}
.category-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.category-content .inputs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.input-wrapper {
  display: flex;
  width: 26%;
  flex-direction: column;
  padding: 5px 12px;
  margin: 15px 8px 8px 8px;
}
.category-content .inputs input {
  outline: none;
  font-size: 12px;
  padding: 15px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 90%;
}

.error-msg {
  padding-top: 6px;
  margin-bottom: 0px;
  font-size: 12px;
  color: red;
  text-align: left;
}

.upload-file input {
  display: none;
}
.upload-file {
  margin: 30px 0px 10px 0px;
  padding: 15px;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  border-radius: 20px;
  line-height: 1.8;
  width: 40%;
}
.upload-file p {
  letter-spacing: 0px;
  color: #393f45;
  font-size: 12px;
  padding-bottom: 20px;
  line-height: 1.8;
}
.upload-file label {
  cursor: pointer;
  padding: 10px 24px;
  background: #febb12;
  color: #fff;
  font-size: 12px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}
.upload-file label.active {
  border: 1px solid red !important;
}
.add-btn {
  margin: 40px 0 10px 0;
  width: 40%;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}

.image-upload-box {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 25px;
  align-items: center;
}
.image-upload-box img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}
.image-upload-box .upload-file {
  width: 145px;
  height: 90px;
}
p.firstNameError,
p.lastNameError {
  position: absolute;
  left: 45px;
  top: 135px;
  font-size: 10px;
  color: red;
}
p.lastNameError {
  left: 275px;
}

.pass-field {
  position: relative;
}

.pass-field img {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 20px;
  cursor: pointer;
}
/* responsive */
@media (max-width: 479px) and (min-width: 320px) {
  .primary-login {
    width: 84%;
  }
}
</style>
