<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>FAQ</h2>
        <p>
          You can see the FAQ content here also you can edit the page content
          here
        </p>
      </div>
      <section class="primary">
        <div class="add-block">
          <div>
            <h4>Editor</h4>
            <p>Add the heading and text here</p>
          </div>
          <div v-for="(faq, index) in faqs" :key="index" class="inputs">
            <div>
              <input type="text" placeholder="Question" v-model="faq.title" />
            </div>
            <div>
              <textarea
                placeholder="Type answer here"
                v-model="faq.text"
              ></textarea>
            </div>
            <div>
              <input
                type="text"
                placeholder="Question in Arabic"
                v-model="faq.title_ar"
              />
            </div>
            <div>
              <textarea
                placeholder="Type answer in Arabic here"
                v-model="faq.text_ar"
              ></textarea>
            </div>
          </div>
          <br />
          <br />
          <div>
            <p
              style="
                font-weight: bold;
                color: #febb12;
                font-size: 14px;
                cursor: pointer;
              "
              @click="addFaq"
            >
              + Add 1 more FAQ
            </p>
          </div>
        </div>
      </section>
      <div class="uploadBtn">
        <button @click="update">Update</button>
      </div>
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
export default {
  name: "FAQ",
  components: { DefaultLayout },
  data() {
    return {
      faqs: [],
    };
  },
  methods: {
    getData() {
      let url = "admin/faqs";
      this.$axios.get(url).then((res) => {
        this.faqs = res.data?.faqs.map(function (item) {
          delete item._id;
          return item;
        });
      });
    },
    addFaq() {
      this.faqs.push({
        title: "",
        text: "",
        title_ar: "",
        text_ar: "",
      });
    },
    update() {
      console.log(this.$route.params);
      let url = "admin/faqs";
      let data = {
        faqs: this.faqs,
      };
      this.$axios.post(url, data).then(() => {
        this.$swal({
          title: "Updated!",
          text: "Data updated successfully.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#FEBB12",
          confirmButtonText: "Done",
          reverseButtons: true,
        });
      });
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    faqs: {
      immediate: true,
      handler() {
        console.log(this.faqs);
      },
    },
  },
};
</script>

<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.primary {
  height: 530px;
  overflow-y: scroll;
}
.add-block {
  text-align: left;
  margin: 14px 0;
}
.add-block h4 {
  font-size: 14px;
  margin-bottom: 12px;
}
.add-block p {
  font-size: 12px;
}
.inputs input,
.inputs textarea {
  outline: none;
  font-size: 12px;
  padding: 14px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 30%;
  margin: 14px 0 8px 0;
}
.inputs textarea {
  height: 123px;
  border-radius: 20px;
}
.bottom-add p {
  width: 30%;
  color: #febb12;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}
.uploadBtn button {
  border: none;
  outline: none;
  border-radius: 24px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
  margin-top: 8px;
}
</style>
