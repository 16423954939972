<template>
  <section class="login-signup">
    <div class="primary-login">
      <div class="close-icon" @click="closeSlide">
        <img src="../../assets/images/close-icon.svg" alt />
      </div>
      <div class="service-heading">
        <h2>Add Balance</h2>
      </div>
      <Form @submit="addNewCategory" :validation-schema="schema">
        <div class="category-content">
          <select v-model="type" required>
            <option value="1">Select type</option>
            <option value="Addition">Addition</option>
            <option value="Substraction">Substraction</option>
          </select>
          <div v-if="err.type" class="error-msg">Please select type</div>
          <select
            v-model="customer_id"
            required
            @change="getCustomerPhoneNumber"
          >
            <option value="1">Select Customer</option>
            <option
              v-for="customer in customers"
              :key="customer?._id"
              :value="customer?._id"
            >
              {{ customer?.userId }}
              {{ customer?.email != "" ? " - (" + customer?.email + ")" : "" }}
            </option>
          </select>
          <div v-if="err.customer" class="error-msg">
            Please select customer
          </div>
          <input
            name="phone"
            type="text"
            placeholder="Customer Mobile Number"
            v-model="phone"
            disabled
          />
          <div class="input-wrapper">
            <Field
              name="amount"
              type="text"
              placeholder="Amount (SAR)"
              v-model="amount"
              required
            />
            <ErrorMessage class="error-msg" name="amount" />
          </div>
          <textarea placeholder="Reason" v-model="reason"></textarea>

          <div class="add-btn">
            <button type="submit" :disabled="loading">
              {{ !loading ? "Add" : "Loading..." }}
            </button>
          </div>
        </div>
      </Form>
    </div>
  </section>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "addBalanceModel",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      type: "1",
      customer_id: "1",
      phone: "",
      amount: "",
      reason: "",
      url: "",
      //
      customers: [],
      selected_customer: {},
      err: {},
      loading: false,
    };
  },
  mounted() {
    this.getUsers();
  },
  computed: {
    schema() {
      return yup.object({
        amount: yup
          .number()
          .typeError("Amount must be a number")
          .positive()
          .required()
          .min(1)
          .label("Amount"),
      });
    },
  },
  methods: {
    async addNewCategory() {
      if (this.type == "1") {
        this.err.type = true;
        return;
      }
      if (this.customer_id == "1") {
        this.err.customer = true;
        return;
      }

      this.err.type = false;
      this.err.customer = false;

      let data = {
        type: this.type,
        userId: this.customer_id,
        phone: this.phone,
        amount: this.amount,
        reason: this.reason,
      };
      this.loading = true;
      this.$axios
        .post("admin/add-balance", data)
        .then(() => {
          this.loading = false;
          this.$swal({
            icon: "success",
            title: "Balance added successfully",
            showConfirmButton: false,
            timer: 3000,
          });
          this.closeSlide();
          this.$emit("call");
        })
        .catch(() => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Could not add Balance",
            showConfirmButton: false,
            timer: 3000,
          });
          this.closeSlide();
        });
    },
    async getUsers() {
      this.$axios.get("admin/app-user").then((res) => {
        this.customers = res.data.filter((user) => user.role == "General_User");
        console.log(this.customers);
      });
    },
    getCustomerPhoneNumber() {
      this.selected_customer = this.customers.filter(
        (customer) => customer._id == this.customer_id
      );
      console.log(this.selected_customer);
      this.phone = this.selected_customer[0]?.phone;
    },
    closeSlide() {
      this.$parent.$parent.addBalanceModel = false;
    },
  },
};
</script>

<style scoped>
.login-signup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #0000004d;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.primary-login {
  width: 450px;
  height: 80vh;
  background: #fff;
  padding: 25px;
  border-radius: 20px;
  position: relative;
  overflow: scroll;
}

.error-msg {
  padding-top: 6px;
  margin-bottom: 0px;
  font-size: 12px;
  color: red;
  text-align: left;
}

.input-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 5px 0px;
  margin: 5px 0px 8px 0px;
}

.input-wrapper input {
  width: 100%;
  margin: 0px 0px 0px 0px;
}

.primary-login::-webkit-scrollbar {
  display: none;
}
.primary-login {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.close-icon {
  cursor: pointer;
  top: 26px;
  position: absolute;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  z-index: 99;
}
.service-heading h2 {
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.8;
  font-size: 18px;
}
.category-content {
  padding: 0 50px;
}
.category-content input {
  outline: none;
  font-size: 12px;
  padding: 18px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 90%;
  margin: 20px 0 8px 0;
}

.category-content select {
  outline: none;
  font-size: 12px;
  padding: 18px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 100%;
  margin: 20px 0 8px 0;
}

.category-content textarea {
  outline: none;
  font-size: 12px;
  padding: 14px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 90%;
  margin: 20px 0 8px 0;
}
.category-content textarea {
  height: 123px;
  border-radius: 20px;
}
.upload-file input {
  display: none;
}
.upload-file {
  margin: 30px 0px 10px 0px;
  padding: 15px;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  border-radius: 20px;
  line-height: 1.8;
}
.upload-file p {
  letter-spacing: 0px;
  color: #393f45;
  font-size: 12px;
  padding-bottom: 20px;
  line-height: 1.8;
}
.upload-file label {
  cursor: pointer;
  padding: 10px 24px;
  background: #febb12;
  color: #fff;
  font-size: 12px;
  border-radius: 20px;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}
.upload-file label.active {
  border: 1px solid red !important;
}
.add-btn {
  margin: 40px 0 10px 0;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 32px;
  background: #febb12;
  color: #fff;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
}

.icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #f0f2f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
}

option {
  font-weight: normal;
  font-size: 16px;
  color: #adadad;
}

/* responsive */
@media (max-width: 479px) and (min-width: 320px) {
  .primary-login {
    width: 84%;
  }
}
</style>
