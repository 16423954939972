<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>{{ myPage.page }}</h2>
        <p>
          {{ myPage.subTitle }}
        </p>
      </div>
      <section class="primary">
        <div class="add-block">
          <div>
            <h4>Editor</h4>
            <p>Add the heading and text here</p>
          </div>
          <div class="inputs">
            <div>
              <input type="text" placeholder="Title" v-model="title" />
            </div>
            <br />
            <div style="background-color: #fff; margin-right: 20px">
              <vue-editor v-model="content"></vue-editor>
            </div>
          </div>
          <br />
          <div class="inputs">
            <div>
              <input
                type="text"
                placeholder="Title in Arabic"
                v-model="title_ar"
              />
            </div>
            <br />
            <div style="background-color: #fff; margin-right: 20px">
              <vue-editor v-model="content_ar"></vue-editor>
            </div>
          </div>
        </div>
      </section>
      <div class="uploadBtn">
        <button @click="update">Update</button>
      </div>
    </section>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
import { VueEditor } from "vue3-editor";
export default {
  name: "FrontEnd",
  components: { DefaultLayout, VueEditor },
  data() {
    return {
      title: "",
      content: "<h1>Some initial content</h1>",
      title_ar: "",
      content_ar: "<h1>Some initial content</h1>",
      myPage: null,
      pageData: ["new", "new"],
      pageHeader: [
        {
          id: "privacy-policy",
          page: "Privacy Policy",
          subTitle:
            "You can see the Privacy Policy content here you can edit the page content here",
        },
        {
          id: "faqs",
          page: "FAQ's",
          subTitle:
            "You can see the faq's content here also you can edit the page content here",
        },
        {
          id: "terms",
          page: "Terms of use",
          subTitle:
            "You can see the terms content here also you can edit the page content here",
        },
        {
          id: "about-us",
          page: "About Us",
          subTitle:
            "You can see the about content here also you can edit the page content here",
        },
      ],
    };
  },
  methods: {
    addNew() {
      this.pageData.push("new");
    },
    getData() {
      let url = "";
      if (this.$route.params.page == "privacy-policy") {
        url = "admin/privacy";
      } else if (this.$route.params.page == "terms") {
        url = "admin/terms";
      } else if (this.$route.params.page == "about-us") {
        url = "admin/aboutus";
      }
      this.$axios.get(url).then((res) => {
        if (this.$route.params.page == "privacy-policy") {
          this.title = res.data?.privacyPolicy[0]?.title;
          this.content = res.data?.privacyPolicy[0]?.text;
          this.content = this.content.replaceAll(
            `style="text-align: right;"`,
            `class="ql-align-right"`
          );
          this.content = this.content.replaceAll(
            `style="text-align: center;"`,
            `class="ql-align-center"`
          );
          this.content = this.content.replaceAll(
            `style="text-align: justify;"`,
            `class="ql-align-justify"`
          );

          this.title_ar = res.data?.privacyPolicy[0]?.title_ar;
          this.content_ar = res.data?.privacyPolicy[0]?.text_ar;
          this.content_ar = this.content_ar.replaceAll(
            `style="text-align: right;"`,
            `class="ql-align-right"`
          );
          this.content_ar = this.content_ar.replaceAll(
            `style="text-align: center;"`,
            `class="ql-align-center"`
          );
          this.content_ar = this.content_ar.replaceAll(
            `style="text-align: justify;"`,
            `class="ql-align-justify"`
          );
        } else if (this.$route.params.page == "terms") {
          this.title = res.data?.termsOfUse[0]?.title;
          this.content = res.data?.termsOfUse[0]?.text;
          this.title_ar = res.data?.termsOfUse[0]?.title_ar;
          this.content_ar = res.data?.termsOfUse[0]?.text_ar;
        } else if (this.$route.params.page == "about-us") {
          this.title = res.data?.aboutUs[0]?.title;
          this.content = res.data?.aboutUs[0]?.text;
          this.title_ar = res.data?.aboutUs[0]?.title_ar;
          this.content_ar = res.data?.aboutUs[0]?.text_ar;
        }
      });
    },
    update() {
      debugger;
      let updateContent = this.content;
      updateContent = updateContent.replaceAll(
        `class="ql-align-right"`,
        `style="text-align: right;"`
      );
      updateContent = updateContent.replaceAll(
        `class="ql-align-center"`,
        `style="text-align: center;"`
      );
      updateContent = updateContent.replaceAll(
        `class="ql-align-justify"`,
        `style="text-align: justify;"`
      );
      let updateContentAr = this.content_ar;
      updateContentAr = updateContentAr.replaceAll(
        `class="ql-align-right"`,
        `style="text-align: right;"`
      );
      updateContentAr = updateContentAr.replaceAll(
        `class="ql-align-center"`,
        `style="text-align: center;"`
      );
      updateContentAr = updateContentAr.replaceAll(
        `class="ql-align-justify"`,
        `style="text-align: justify;"`
      );
      let url = "";
      if (this.$route.params.page == "privacy-policy") {
        url = "admin/privacy";
      } else if (this.$route.params.page == "terms") {
        url = "admin/terms";
      } else if (this.$route.params.page == "about-us") {
        url = "admin/aboutus";
      }
      let data = {};
      if (this.$route.params.page == "privacy-policy") {
        data = {
          privacyPolicy: [
            {
              title: this.title,
              text: updateContent,
              title_ar: this.title_ar,
              text_ar: updateContentAr,
            },
          ],
        };
      } else if (this.$route.params.page == "terms") {
        data = {
          termsOfUse: [
            {
              title: this.title,
              text: updateContent,
              title_ar: this.title_ar,
              text_ar: updateContentAr,
            },
          ],
        };
      } else if (this.$route.params.page == "about-us") {
        data = {
          aboutUs: [
            {
              title: this.title,
              text: updateContent,
              title_ar: this.title_ar,
              text_ar: updateContentAr,
            },
          ],
        };
      }
      this.$axios.post(url, data).then(() => {
        this.$swal({
          title: "Updated!",
          text: "Data updated successfully.",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#FEBB12",
          confirmButtonText: "Done",
          reverseButtons: true,
        });
      });
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    "$route.params": {
      immediate: true,
      handler(val) {
        this.myPage = this.pageHeader.find((key) => key.id == val.page);
        this.getData();
      },
    },
    content: {
      immediate: true,
      handler() {
        console.log(this.content);
      },
    },
  },
};
</script>

<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.primary {
  height: 80vh;
  overflow-y: scroll;
}
.add-block {
  text-align: left;
  margin: 14px 0;
}
.add-block h4 {
  font-size: 14px;
  margin-bottom: 12px;
}
.add-block p {
  font-size: 12px;
}
.inputs input,
.inputs textarea {
  outline: none;
  font-size: 12px;
  padding: 14px 20px;
  border-radius: 50px;
  border: none;
  box-shadow: 0px 0px 8px 2px #e9e8e8;
  color: #c4c4c4;
  width: 30%;
  margin: 14px 0 8px 0;
}
.inputs textarea {
  height: 123px;
  border-radius: 20px;
}
.bottom-add p {
  width: 30%;
  color: #febb12;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}
.uploadBtn button {
  border: none;
  outline: none;
  border-radius: 24px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
  margin-top: 8px;
}
</style>
