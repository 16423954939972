<template>
  <default-layout>
    <section class="main-wrapper">
      <div class="top-heading">
        <h2>Cities</h2>
        <p>You can see all the citiess here. You can also add new ciities</p>
      </div>
      <div class="add-btn">
        <button @click="CityModelShow">Add New City</button>
      </div>
      <div class="category-main">
        <div
          class="category-card"
          v-for="(city, index) in cities"
          :key="index"
        >
          <div class="icon">
            <img class="hotel-icon" :src="city?.image" />
          </div>
          <h6>{{ city.name }}</h6>
          <div class="bottom-icons">
            <img class="icon" src="../assets/images/edit.svg" @click="editCity(city)" />
            <label class="switch">
              <input
                type="checkbox"
                :checked="city.status == 'active' ? true : false"
                @click="(e) => toggleActive(e, city._id)"
              />
              <span class="slider round"></span>
            </label>
          </div>
          <!-- <div class="bottom-icons">
            <h3>{{ city.count }}</h3>
          </div> -->
        </div>
      </div>
      <CityModel v-if="cityModel" @reCall="getData"/>
      <EditCityModel v-if="editCityModel" :editData="editData" @reCall="getData"/>
    </section>
  </default-layout>
</template>

<script>
import CityModel from "@/components/Models/CityModel.vue";
import EditCityModel from "@/components/Models/EditCityModel.vue";
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  name: "CityView",
  components: {
    DefaultLayout,
    CityModel,
    EditCityModel,
  },
  data() {
    return {
      cityModel: false,
      editCityModel: false,
      cities: [],
      editData: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        // var res = await this.$axios.get("admin/service-by-city");
        var res = await this.$axios.get("admin/city");
        if (res) {
          this.cities = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    CityModelShow() {
      this.cityModel = !this.cityModel;
    },
    async toggleActive(e, id) {
      this.$swal({
        toast: true,
        title: "City Status Updated!!!",
        icon: "success",
        animation: false,
        position: "top-right",
        showConfirmButton: false,
        timer: 3000,
      });
      if (e.target.checked) {
        await this.$axios.put(`admin/active-city/${id}`);
      } else {
        await this.$axios.put(`admin/inactive-city/${id}`);
      }
    },
    editCity(city){
      this.editData = city;
      this.editCityModel = !this.editCityModel;
    }
  },
};
</script>

<style scoped>
.top-heading {
  line-height: 1.8;
}
.top-heading h2 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  font-size: 16px;
}
.top-heading p {
  text-align: left;
  letter-spacing: 0px;
  color: #808183;
  font-size: 12px;
}
.add-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.add-btn button {
  border: none;
  outline: none;
  border-radius: 11px;
  background: #febb12;
  color: #fff;
  padding: 12px 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 4px 1px #c9c9c9a6;
  width: 17%;
}
.category-main {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
  /* justify-content: space-between; */
  padding: 20px 0;
}
.category-card {
  width: 16%;
  margin: 0 0 -12px 0px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}
.category-card .icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #f0f2f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-card .icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.category-card h6 {
  letter-spacing: 0px;
  color: #6a6a6a;
  font-size: 14px;
  text-align: center;
  padding: 15px 0 10px 0;
  font-weight: normal;
}
.bottom-icons {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottom-icons h3 {
  letter-spacing: 0px;
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0;
}

.bottom-icons .icon {
  width: 50px;
  height: 50px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #febb12;
}

input:focus + .slider {
  box-shadow: 0 0 1px #febb12;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
